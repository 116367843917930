import React, { useReducer } from 'react';
import Colors from '@components/shared/Colors';
import { NavBarCategory, NavStatus } from '@contracts';

export const initialState: NavState = {
  status: NavStatus.Default,
  color: Colors.forestGreen,
  navbarSelectedCategories: [],
  navbarItemColor: Colors.navyBlue,
  navbarColor: Colors.cream,
  showingBanner: true,
  basketCount: 0,
  logo: null
};

const mockDispatch = (a: ActionTypes) => {
  console.debug(a);
};

const initial: IContext = {
  navState: initialState,
  navDispatch: mockDispatch
};

const NavContext = React.createContext(initial);

function reducer(state: NavState, action: ActionTypes): NavState {
  switch (action.type) {
    case 'setStatus':
      return {
        ...state,
        status: action.data
      };
    case 'setNavbarColor':
      return {
        ...state,
        navbarColor: action.data
      };
    case 'setColor':
      return {
        ...state,
        color: action.data
      };
    case 'setShowingBanner':
      return {
        ...state,
        showingBanner: action.data
      };
    case 'setNavbarBasketCount':
      return {
        ...state,
        basketCount: action.data
      };
    case 'setNavbarSelectedCategory': {
      const navbarSelectedCategories = [...state.navbarSelectedCategories.filter((category) => category !== action.data)];

      navbarSelectedCategories.push(action.data);

      return {
        ...state,
        navbarSelectedCategories
      };
    }
    case 'removeNavbarSelectedCategory': {
      const navbarSelectedCategories = [...state.navbarSelectedCategories.filter((category) => category !== action.data)];

      return {
        ...state,
        navbarSelectedCategories
      };
    }
    case 'removeNavbarSelectedCategories': {
      const navbarSelectedCategories = [];

      if (action?.data) {
        navbarSelectedCategories.push(action.data);
      }

      return {
        ...state,
        navbarSelectedCategories
      };
    }
    case 'setLogo':
      return { ...state, logo: action.data };
    default:
      throw new Error(JSON.stringify(action, null, 2));
  }
}

interface Props {
  children: React.ReactNode;
}

function NavProvider({ children }: Props) {
  const [navState, navDispatch] = useReducer(reducer, initialState);

  return (
    <NavContext.Provider
      value={{
        navState,
        navDispatch
      }}
    >
      {children}
    </NavContext.Provider>
  );
}

export interface NavState {
  status: NavStatus;
  color: string;
  navbarColor: string;
  navbarItemColor: string;
  navbarSelectedCategories: NavBarCategory[];
  basketCount: number;
  showingBanner: boolean;
  logo: React.ReactNode | null;
}

export interface IContext {
  navState: NavState;
  navDispatch(action: ActionTypes): void;
}

interface SetStatus {
  type: 'setStatus';
  data: NavStatus;
}

interface SetColor {
  type: 'setColor';
  data: string;
}

interface SetShowingBanner {
  type: 'setShowingBanner';
  data: boolean;
}

interface SetNavbarColor {
  type: 'setNavbarColor';
  data: string;
}

interface SetNavbarBasketCount {
  type: 'setNavbarBasketCount';
  data: number;
}

interface SetNavbarSelectedCategory {
  type: 'setNavbarSelectedCategory';
  data: NavBarCategory;
}

interface RemoveNavbarSelectedCategory {
  type: 'removeNavbarSelectedCategory';
  data: NavBarCategory;
}

interface RemoveNavbarSelectedCategories {
  type: 'removeNavbarSelectedCategories';
  data?: NavBarCategory;
}

interface SetLogo {
  type: 'setLogo';
  data: React.ReactNode;
}

export type ActionTypes =
  | SetStatus
  | SetColor
  | SetShowingBanner
  | SetNavbarColor
  | SetNavbarBasketCount
  | SetNavbarSelectedCategory
  | RemoveNavbarSelectedCategories
  | RemoveNavbarSelectedCategory
  | SetLogo;

export { NavContext, NavProvider };
