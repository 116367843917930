import { BillingInfo, MedicalInsights, MedicalResults, Orders, Subscription, Subscriptions as SubscriptionsType } from '@dayetopia/types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Treatment } from '@contracts';

export enum PageOptions {
  Nav = 'nav',
  Dashboard = 'dashboard',
  MicrobiomeScreening = 'microbiome-screening',
  EndoAssessment = 'endo-assessment',
  PCOSAssessment = 'pcos-assessment',
  Contraception = 'contraception',
  Purchases = 'purchases',
  Referrals = 'referrals',
  HealthProfileInfo = 'health-profile',
  AccountSettings = 'account-settings',
  EnableNewFeatures = 'enable-new-features',
  ScreeningReport = 'screening-report',
  HowToUse = 'how-to-use',
  FoundersMicrobiome = 'founders-microbiome',
  ActivateTest = 'activate-test',
  OrderNewKit = 'order-new-kit',
  OrderReplacementKit = 'order-replacement-kit',
  Subscriptions = 'subscriptions',
  Prescriptions = 'prescriptions',
  ArrangeSampleCollection = 'arrange-sample-collection',
  MeetMicrobiome = 'meet-microbiome',
  WhatIsMicrobiomeScreening = 'what-is-vaginal-microbiome-screening',
  WhatIsStiScreening = 'what-is-sti-screening',
  BookACall = 'book-a-call',
  RequestTreatment = 'request-treatment',
  UpdateHealthContext = 'update-health-context',
  SendSample = 'send-sample',
  PeriodPainReport = 'period-pain-report',
  Products = 'products',
  Vitals = 'vitals',
  PeriodPainClinic = 'period-pain-clinic'
}

export interface MarketingPreferences {
  marketingEmail: boolean;
  newsletterSMS: boolean;
  marketingSMS: boolean;
}

export interface UserInfoProps {
  accountId: string;
  firstName: string;
  lastName: string;
  pronouns: string;
  email: string;
  telephone: string;
  profileIcon: string;
  dateOfBirth: string;
  marketingPreferences: MarketingPreferences;
  credits?: any;
  termsAcceptedDate?: string;
  vmsEnabledDate?: string;
  hasAcceptedDate?: boolean;
  referralCode?: string;
  personaVerificationId?: string;
  isMfaEnabled: false;
}

export enum ResultTypes {
  GoodBacteria = 'GoodBacteria',
  AerobicMicroorganisms = 'AerobicMicroorganisms',
  AnaerobicMicroorganisms = 'AnaerobicMicroorganisms',
  Yeasts = 'Yeasts',
  Mycoplasmas = 'Mycoplasmas'
}

export interface HealthProfileProps {
  results: MedicalResults;
  insights: MedicalInsights;
  treatments: Treatment[];
}

interface PurchaseProps {
  orders?: Orders;
  subscriptions?: SubscriptionsType;
  billingInfo?: BillingInfo;
  selectedSubscriptionId: string;
  fetchedAccountData: boolean;
  selectedSub?: Subscription;
}

export enum AccountIcons {
  Initials = 'Initials',
  MediumHair = 'MediumHair',
  LongHair = 'LongHair',
  Bun = 'Bun',
  Afro = 'Afro',
  ShortHair = 'ShortHair'
}

export const pronounOptions = [
  'she/her',
  'they/them',
  'he/him',
  'ae/aer',
  'e/em',
  'ey/em',
  'fae/faer',
  'per/per',
  'sie/sie',
  'tey/ter',
  've/ver',
  'xe/xem',
  'ze/hir',
  'zie/hir'
];

export const pronounSelectOptions = pronounOptions.map((pronoun) => ({ value: pronoun, label: pronoun }));

export const userInfo: UserInfoProps = {
  accountId: '',
  firstName: '',
  lastName: '',
  pronouns: '',
  email: '',
  telephone: '',
  dateOfBirth: '',
  profileIcon: AccountIcons.Initials,
  marketingPreferences: {
    marketingEmail: false,
    newsletterSMS: false,
    marketingSMS: false
  },
  credits: [],
  termsAcceptedDate: undefined,
  vmsEnabledDate: undefined,
  personaVerificationId: undefined,
  isMfaEnabled: false
};

const healthProfile: HealthProfileProps = {
  insights: [],
  results: [],
  treatments: []
};

const purchases: PurchaseProps = {
  orders: [],
  subscriptions: [],
  billingInfo: undefined,
  selectedSubscriptionId: '',
  fetchedAccountData: false
};

export interface UseAccountStoreProps {
  userInfo: UserInfoProps;
  purchases: PurchaseProps;
  healthProfile: HealthProfileProps;
  loading: boolean;
  healthProfileLoaded: boolean;
  setUserInfo: (value: any) => void;
  setPurchases: (value: any) => void;
  setHealthProfile: (value: any) => void;
  setLoading: (value: boolean) => void;
  setHealthProfileLoaded: (value: boolean) => void;
  reset: () => void;
}

export const useAccountStore = create(
  persist<UseAccountStoreProps>(
    (set) => ({
      userInfo,
      setUserInfo: (newValue: any) => set({ userInfo: newValue }),
      purchases,
      setPurchases: (newValue: any) => set({ purchases: newValue }),
      healthProfile,
      setHealthProfile: (newValue: any) => set({ healthProfile: newValue }),
      healthProfileLoaded: false,
      loading: true,
      setLoading: (val: boolean) => set({ loading: val }),
      setHealthProfileLoaded: (val: boolean) => set({ healthProfileLoaded: val }),
      reset: () => set({ userInfo, purchases, healthProfile, healthProfileLoaded: false })
    }),
    {
      name: 'vms-state'
    }
  )
);
