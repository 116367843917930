import { CartItem, CartItemType, ShippingFees, ProductCode } from '@contracts';

interface ShippingCostProps {
  price: number;
  freeShippingRequirement: number;
  cartItems: CartItem[];
  shippingFees: Partial<ShippingFees>;
}

export function calculateShippingCost({ price, freeShippingRequirement, cartItems, shippingFees }: ShippingCostProps) {
  let shippingCostValue: number = 0;
  let originalShippingCostValue: number = 0;
  const freeShippingRequirementMet = price > freeShippingRequirement;
  const maxOneOffPurchaseShippingFee = cartItems
    .filter((item) => item.type === CartItemType.OneOffPurchase && !item.id.includes('_free'))
    .reduce(
      (previousValue, cartItem: CartItem) => {
        const codeForShipping = cartItem.productCode;

        return {
          ...previousValue,
          maxOriginalShippingFee: Math.max(previousValue.maxOriginalShippingFee, shippingFees[codeForShipping]?.originalShippingPrice ?? 0),
          maxShippingFee: Math.max(previousValue.maxShippingFee, shippingFees[codeForShipping]?.shippingPrice ?? 0)
        };
      },
      { maxOriginalShippingFee: 0, maxShippingFee: 0 }
    );

  shippingCostValue += maxOneOffPurchaseShippingFee.maxShippingFee;
  originalShippingCostValue += maxOneOffPurchaseShippingFee.maxOriginalShippingFee;

  const cartItemsWithoutOneOffs = cartItems.filter((item) => item.type !== CartItemType.OneOffPurchase);

  cartItemsWithoutOneOffs.forEach((item) => {
    const codeForShipping = item.productCode;

    shippingCostValue += shippingFees[codeForShipping]?.shippingPrice ?? 0;
    originalShippingCostValue += shippingFees[codeForShipping]?.originalShippingPrice ?? 0;
    item.shippingFee = freeShippingRequirementMet ? 0 : shippingFees[codeForShipping]?.shippingPrice ?? 0;
  });

  if (freeShippingRequirementMet) {
    return { originalShippingPrice: originalShippingCostValue, shippingPrice: 0 };
  }

  const freeItemCheck = cartItems.length === 1 && cartItems.find((item) => item.price === 0);
  const oneOffTamponBoxCheck = cartItems.find((item) => item.productCode === ProductCode.TamponBox && item.type === CartItemType.OneOffPurchase);

  if (freeItemCheck || oneOffTamponBoxCheck) {
    return { originalShippingPrice: originalShippingCostValue, shippingPrice: originalShippingCostValue };
  }

  return { originalShippingPrice: originalShippingCostValue, shippingPrice: shippingCostValue };
}
