import checkResStatus from '@utils/checkResStatus';

export default function useFetchImplementation(firebaseAuth: any, omsUrl: string, klavioKey?: string) {
  async function useBuildOptions(method: string, body: any, addKlavioKey: boolean, currentUser: any = null) {
    const finalCurrentUser = firebaseAuth?.currentUser || currentUser;
    const googleIdToken = await finalCurrentUser.getIdToken();

    if (!googleIdToken) {
      console.debug('');
      return;
    }

    let options: any = {
      method,
      headers: {
        token: googleIdToken,
        'Content-Type': 'application/json; charset=utf-8'
      }
    };

    if (body) {
      options = {
        ...options,
        body: ensureJson(body)
      };
    }

    if (addKlavioKey) {
      options = {
        ...options,
        headers: {
          ...options.headers,
          'api-key': klavioKey
        }
      };
    }

    return options;
  }

  function ensureJson(body: any) {
    let ensureBody = body;
    if (!isJson(body)) {
      ensureBody = JSON.stringify(body);
    }
    return ensureBody;
  }

  function isJson(string: string) {
    try {
      JSON.parse(string);
    } catch (e) {
      return false;
    }
    return true;
  }

  async function get(path: string, currentUser?: any) {
    const options = await useBuildOptions('GET', null, false, currentUser);
    return fetch(`${omsUrl}${path}`, options)
      .then((res) => checkResStatus(res))
      .then((json) => json)
      .catch((err) => {
        throw err;
      });
  }

  async function put(path: string, body: any) {
    const options = await useBuildOptions('PUT', body || null, false);
    return fetch(`${omsUrl}${path}`, options)
      .then((res) => checkResStatus(res))
      .then((json) => json)
      .catch((err) => {
        throw err;
      });
  }

  async function post(path: string, body: any, addKlavioKey = false) {
    const options = await useBuildOptions('POST', body, addKlavioKey);
    return fetch(`${omsUrl}${path}`, options)
      .then((res) => checkResStatus(res))
      .then((json) => json)
      .catch((err) => {
        throw err;
      });
  }

  return {
    get,
    put,
    post
  };
}
