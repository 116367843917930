import { DEFAULT_LANGUAGE } from '@constants';
import { Locale } from '@contracts';
import i18n from '../../i18n.js';

/**
 * Sets the language based on the current URL.
 */
export function setLanguage() {
  const language = getCurrentPageLanguage();

  if (language) {
    i18n.changeLanguage(language);
  }
}

/**
 * Extracts the language from a given URL.
 *
 * @param {string} url - The URL to extract the language from.
 * @return {string} The extracted language.
 */
export const getLanguageFromUrl = (url: string) => {
  const pathSegment = url.split('/')[1];

  return pathSegment || '';
};

/**
 * Determines whether a given URL is an internal link.
 *
 * @param {string} path - The URL to check.
 * @return {boolean} True if the URL is internal, false otherwise.
 */
export const isInternalLink = (path: string) => {
  return path.startsWith('/') && !path.startsWith('//');
};

/**
 * Returns the localized URL for a given path.
 *
 * @param {string} path - The path to localize.
 * @param {string} [currentLanguage] - The current language (optional). If not provided, the current page language is used.
 * @param {boolean} [noInternalCheck] - No internal link check (optional). If not provided, the function will check if the link is internal or not, this parameter is used to avoid additional check if that is already checked.
 * @return {string} The localized path. Adds the language prefix to internal links if the language is not default.
 */
export const getLocaleUrlForPath = (path: string, currentLanguage?: string, noInternalCheck?: boolean) => {
  if (!noInternalCheck && !isInternalLink(path)) {
    return path;
  }

  const finalCurrentLanguage = currentLanguage || getCurrentPageLanguage();
  const lang = i18n.find((language: Locale) => language.path === finalCurrentLanguage);

  let finalPath = path;

  // Reset language prefixes if any
  i18n.forEach((language: Locale) => {
    if (path.startsWith(`/${language.path}/`)) {
      finalPath = finalPath.replaceAll(`/${finalCurrentLanguage}`, '');
    }
  });

  // Add language path only if it's not the default language
  if (lang && !lang.default) {
    return `/${lang.path}${finalPath}`;
  }

  return finalPath;
};

/**
 * Gets the current page language or the default language.
 *
 * @return {string} The current language or the default language if undefined.
 */
export const getCurrentPageLanguage = () => {
  if (typeof window === 'undefined') {
    return i18n.find((language: Locale) => language.default)?.path || DEFAULT_LANGUAGE;
  }

  const urlLanguage = getLanguageFromUrl(window.location.pathname);

  return (
    i18n.find((language: Locale) => language.path === urlLanguage)?.path ||
    i18n.find((language: Locale) => language.default)?.path ||
    DEFAULT_LANGUAGE
  );
};
