import React from 'react';
import { Link } from 'gatsby';
import { LinkTranslatedProps } from '@contracts';
import { getCurrentPageLanguage, getLocaleUrlForPath, isInternalLink } from '@utils/language';

/**
 * This component is used to translate and handle internal and external links throughout the app.
 * It determines the type of link and renders a Gatsby Link or a standard anchor tag appropriately.
 * If `targetBlank` is true, or target='_blank' attribute is passed, the link will open in a new
 * tab with secure attributes.
 *
 * @param {LinkTranslatedProps} props The props for the LinkTranslated component.
 * @returns {JSX.Element} The translated link as a Gatsby Link or an anchor tag.
 */
const LinkTranslated = React.forwardRef<HTMLAnchorElement, LinkTranslatedProps>(({ to, href, language, targetBlank, children, ...rest }, ref) => {
  const normalisedTo = to || href || '#';
  const isInternal = isInternalLink(normalisedTo);
  const currentLanguage = language || getCurrentPageLanguage();
  const finalAttributes = { ...rest };
  const finalTo = isInternal ? getLocaleUrlForPath(normalisedTo, currentLanguage, true) : normalisedTo;

  if (targetBlank) {
    finalAttributes.target = '_blank';
  }

  /**
   * Automatically adds 'rel="noopener noreferrer"' when 'target="_blank"' is used.
   * This is important for security when opening links in a new tab, as it prevents
   * the newly opened page from accessing the 'window.opener' property. This attribute
   * is especially relevant for external links but is handled here for any link with
   * 'target="_blank"' for consistency and security best practices.
   */
  if (finalAttributes.target === '_blank') {
    finalAttributes.rel = 'noopener noreferrer';
  }

  /**
   * When `targetBlank` is true for internal links, it's a known issue in Gatsby that the Link component
   * doesn't support target="_blank" out of the box, hence the use of a regular anchor tag in this case.
   * Reference: [Gatsby Issue #13825](https://github.com/gatsbyjs/gatsby/issues/13825)
   */
  return isInternal && finalAttributes.target !== '_blank' ? (
    <Link ref={ref as any} to={finalTo} {...finalAttributes}>
      {children}
    </Link>
  ) : (
    <a ref={ref as any} href={finalTo} hrefLang={currentLanguage} {...finalAttributes}>
      {children}
    </a>
  );
});

LinkTranslated.displayName = 'LinkTranslated';

export default LinkTranslated;
