import { createElement } from 'react';
import { CountryCode, CountryName, ShippingAddress } from '@dayetopia/types';
import { WindowLocation } from '@reach/router';
import LinkTranslated from '@components/shared/LinkTranslated';
import { DEFAULT_KLAVIYO_LIST_ID, DEFAULT_KLAVIYO_LIST_ID_US } from '@constants';
import { ApplePayShippingContact, CartItem, GooglePayShippingContact, ProductCode, PromotionObject } from '@contracts';

export const capitalizeFirstLetter = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export const resetAddressKeepCountry = (address: ShippingAddress) => {
  const { country } = address;
  return { country };
};

export const pageIsAccountPage = (location: string) => location.includes('/dashboard/') || location.includes('/account/');

export const pageIsLoginPage = (location: string) => location.includes('/dashboard/login');

export const pageIsFocusPage = (location: string) =>
  location.includes('/login') ||
  location.includes('/reset-password') ||
  location.includes('/forgot-password') ||
  location.includes('/activate-test') ||
  location.includes('/enable-new-features') ||
  location.includes('/request-treatment') ||
  location.includes('/update-health-context') ||
  location.includes('/book-a-call') ||
  location.includes('/reactivate-subscription') ||
  location.includes('/ppc-questionnaire') ||
  location.includes('/update-billing');

/**
 * Checks if the given pathname ends with '/ads' or '/ads/'.
 *
 * @param {string} pathname - The pathname to check.
 * @returns {boolean} True if the pathname ends with '/ads' or '/ads/', otherwise false.
 */
export const pageIsAdsPage = (pathname: string): boolean => {
  return pathname.endsWith('/ads') || pathname.endsWith('/ads/');
};

export const encryptEmail = async (email: string) => {
  const hash = '8487513bc54d7fbb71b9bf28c7a38ca9b18c86eac103c920573ccb69727cc004';
  const hashed = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(email.toLowerCase() + hash));
  return [].map.call(new Uint8Array(hashed), (b) => `00${b.toString(16)}`.slice(-2)).join('');
};

export const shuffleArray = (array: any[]) => {
  let currentIndex = array.length;
  let randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};

export const getYouTubeEmbedUrl = (videoUrl: string): string | null => {
  const youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([\w-]{11})/i;
  const match = videoUrl.match(youtubeRegex);

  if (match && match[1]) {
    const videoId = match[1];
    return `https://www.youtube.com/embed/${videoId}`;
  }

  return null;
};

export const isEmptyObject = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;

export const convertApplePayAddressToRecurly = (shippingAddress: ApplePayShippingContact): ShippingAddress => {
  const street1 = shippingAddress.addressLines && shippingAddress.addressLines.length > 0 ? shippingAddress.addressLines[0] : '';
  const street2 = shippingAddress.addressLines && shippingAddress.addressLines.length > 1 ? shippingAddress.addressLines[1] : '';

  return {
    firstName: shippingAddress.givenName,
    lastName: shippingAddress.familyName,
    street1,
    street2,
    city: shippingAddress.locality,
    region: shippingAddress.administrativeArea,
    postalCode: shippingAddress.postalCode,
    country: shippingAddress.country,
    phone: shippingAddress.phoneNumber
  };
};

export const convertGooglePayAddressToRecurly = (shippingAddress: GooglePayShippingContact): ShippingAddress => {
  const street1 = shippingAddress.address1;
  const street2 = `${shippingAddress.address2} ${shippingAddress.address3}`;
  const [firstName, ...lastNameParts] = shippingAddress.name.split(' ');
  const lastName = lastNameParts.join(' ');

  return {
    firstName,
    lastName,
    street1,
    street2,
    city: shippingAddress.locality,
    region: shippingAddress.administrativeArea,
    postalCode: shippingAddress.postalCode,
    country: shippingAddress.countryCode,
    phone: shippingAddress.phoneNumber
  };
};

export const isCountryMatch = (treatmentCountry: string, countryCode: CountryCode): boolean => {
  if (treatmentCountry.startsWith('-')) {
    return treatmentCountry.slice(1) !== countryCode;
  }
  return treatmentCountry === countryCode;
};

export const getSearchParameterByName = <T>(name: string, location: WindowLocation<unknown>): T => {
  const query = new URLSearchParams(location.search);

  return query.get(name) as any;
};

export function findCountryKeyByName(countryName?: string): CountryCode | undefined {
  if (!countryName) {
    return undefined;
  }

  const countryKey = Object.keys(CountryName).find((key) => CountryName[key] === countryName);

  return (countryKey as CountryCode) || undefined;
}

// for e.g. -US would show in all apart from US, and US,GB would show only in both US & GB, works with , separators too
export function showInCountryCondition(showInCountries?: string, country?: CountryCode | string): boolean {
  if (!country || !showInCountries?.length) {
    return true;
  }

  const showInCountriesArray = showInCountries
    ?.split(',')
    .map((c) => c.trim())
    .filter((c) => !!c);

  if (!showInCountriesArray?.length) {
    return true;
  }

  let include = false;
  let exclude = false;

  showInCountriesArray.forEach((showInCountrySingle) => {
    if (showInCountrySingle.startsWith('-')) {
      if (showInCountrySingle.slice(1) === country) {
        exclude = true;
      }
    } else if (showInCountrySingle === country) {
      include = true;
    }
  });

  if (exclude) {
    return false;
  }

  return include || showInCountriesArray.every((c) => c.startsWith('-'));
}

/**
 * Filters an array of items based on the specified country.
 *
 * @template T - The type of the items.
 * @param {Array<T>} menuItems - The array of items to filter.
 * @param {CountryCode | string | undefined} country - The country code or name to filter by.
 * @returns {Array<T>} The filtered array of items that match the country condition.
 */
export function filterShowInCountries<T>(menuItems: Array<T>, country: CountryCode | string | undefined): Array<T> {
  return (
    menuItems?.filter((item: any) => {
      const showInCountries = item?.show_in_countries?.text || item?.show_in_countries;

      return showInCountryCondition(showInCountries, country);
    }) || []
  );
}

export const isFestivePeriod = (): boolean => {
  const today = new Date();
  const month = today.getMonth();
  const day = today.getDate();

  const decemberMonth = 11;
  const januaryMonth = 0;

  return (month === decemberMonth && day >= 1) || (month === januaryMonth && day <= 3);
};

export const itemsContainDTProduct = (cartItems: CartItem[]): boolean =>
  cartItems.some(
    (item) =>
      item.productCode === ProductCode.VMSEssential ||
      item.productCode === ProductCode.VMSEssentialConsultation ||
      item.productCode === ProductCode.VaginalHealthBundle ||
      item.productCode === ProductCode.STIScreening ||
      item.productCode === ProductCode.VMSAdvanced ||
      item.productCode === ProductCode.ScreeningKit
  );

/*
 * Get icons array from prismic usp icons data
 */
export function getUspIconsFromData(sliceContainingIcons: any) {
  const icons = [];

  if (sliceContainingIcons?.icon_1?.gatsbyImageData) {
    icons.push({
      icon: sliceContainingIcons?.icon_1,
      icon_text: sliceContainingIcons?.icon_1_text
    });
  }

  if (sliceContainingIcons?.icon_2?.gatsbyImageData) {
    icons.push({
      icon: sliceContainingIcons?.icon_2,
      icon_text: sliceContainingIcons?.icon_2_text
    });
  }

  if (sliceContainingIcons?.icon_3?.gatsbyImageData) {
    icons.push({
      icon: sliceContainingIcons?.icon_3,
      icon_text: sliceContainingIcons?.icon_3_text
    });
  }

  return icons;
}

export const getSanitizedKey = (key = '', length = 20) => {
  let sanitizedKey = key.replace(/\s+/g, '_').toLowerCase();
  sanitizedKey = sanitizedKey.substring(0, length);
  return sanitizedKey;
};

export const isPromotionActive = (promo: PromotionObject, country: string, productCode?: string): boolean => {
  const currentDate = new Date();
  const startDate = new Date(promo.startDate);
  const endDate = new Date(promo.endDate);
  const isInCountry = promo.countries.length === 0 || promo.countries.includes(country);
  const isDateValid = currentDate >= startDate && currentDate <= endDate;
  const isProductValid = !promo.productCodes || promo.productCodes.length === 0 || !productCode || promo.productCodes.includes(productCode);

  return isDateValid && isInCountry && isProductValid;
};

export const createPreconfiguredMailtoLink = (
  supportEmail: string,
  supportEmailSubject: string,
  supportEmailBody: string,
  customerName: string,
  orderNumber: string
) => {
  const encodedSubject = encodeURIComponent(supportEmailSubject);

  // The function expects $customer_name and $order_number as variables in the supportEmailBody and replaces all occurrences with userInfo.firstName+userInfo.lastName and result.id resprectivelly
  const filledBody = supportEmailBody.replace(/\$customer_name/g, customerName).replace(/\$order_number/g, orderNumber);
  const encodedBody = encodeURIComponent(filledBody);

  return `mailto:${supportEmail}?subject=${encodedSubject}&body=${encodedBody}`;
};

export const hasAnyRelationsPrismic = (listOfRelations: { uid: string | null }[]) => {
  return listOfRelations.length > 0 && listOfRelations.every((item) => !!item.uid);
};

/**
 * Checks if the current environment is development.
 *
 * @returns {boolean} True if the environment is development, otherwise false.
 */
export const isDevelopmentEnvironment = (): boolean => {
  return !process.env.GATSBY_ACTIVE_ENV || process.env.GATSBY_ACTIVE_ENV === 'development';
};

/**
 * Converts a string containing HTML anchor tags into an array of strings and React elements.
 * Anchor tags are transformed into clickable links that open in a new tab.
 *
 * @param {string} text - The input string that may contain HTML anchor tags
 * @returns {string | (string | JSX.Element)[]} Either the original string, an empty string if input is falsy, or an array of strings and React anchor elements
 * @example
 * // Returns: ['Hello ', <a href="https://example.com">click here</a>, ' to continue']
 * renderStringWithLinkTags('Hello <a href="https://example.com">click here</a> to continue')
 *
 * // Returns: 'String without tags'
 * renderStringWithLinkTags('String without tags')
 *
 * // Returns: ''
 * renderStringWithLinkTags('')
 */

export const renderStringWithLinkTags = (text: string): string | (string | JSX.Element)[] => {
  if (!text) return '';

  const parts = text.split(/(<a.*?<\/a>)/).map((part, index) => {
    if (part.startsWith('<a')) {
      const href = part.match(/href="(.*?)"/)?.[1];
      const replacedText = part.replace(/<[^>]+>/g, '');
      return createElement(
        LinkTranslated,
        {
          key: index,
          to: href,
          targetBlank: true
        },
        replacedText
      );
    }
    return part;
  });

  // Trim empty strings from start and end
  while (parts.length && parts[0] === '') parts.shift();
  while (parts.length && parts[parts.length - 1] === '') parts.pop();

  return parts;
};

/**
 * Returns the appropriate Klaviyo list ID based on the user's country.
 *
 * @param {CountryCode} country - The country code to check against
 * @returns {string} The Klaviyo list ID - returns US-specific list ID for US users, default list ID for all other countries
 */
export const getKlaviyoTrackingListId = (country: CountryCode) => {
  return country === CountryCode.US ? DEFAULT_KLAVIYO_LIST_ID_US : DEFAULT_KLAVIYO_LIST_ID;
};
