import { CountryCode } from '@dayetopia/types';
import { PadSizeType, ProductCode, ScreeningType } from '@contracts';

export const MIN_DESKTOP_SIZE_PX = 1024;

export const LIVE_DOMAIN = 'yourdaye.com';
export const DEFAULT_LANGUAGE = 'en';

export const EXTREME_GEO_LOOKUP_URL = 'https://extreme-ip-lookup.com/json/?key=fakvZawD674Bd4sbj6sh';
export const SITE_ICON_URL = 'https://images.prismic.io/daye/cc60bac082010814f1d1ebcb10f1c418819334b5_favicon.png?auto=compress,format';
export const DAYE_LOGO_URL =
  'https://images.prismic.io/ecommerce-website/4d1a02fa-e15a-45a2-9d8b-4375efc8803c_Daye+monogram.png?auto=compress,format';
export const PRIVACY_POLICY_URL = '/privacy/';
export const TERMS_URL = '/terms/';
export const HELP_URL = 'https://yourdaye.zendesk.com/hc/en-us';
export const VITALS_URL = '/vitals/';
export const ALL_PRODUCTS_URL = '/products/';
export const ABOUT_US_URL = '/about/';
export const PERIOD_CARE_URL = '/products/period-care/';
export const VAGINAL_HEALTH_URL = '/products/vaginal-care/';
export const PERIOD_PAIN_CLINIC_URL = '/period-pain-clinic/';
export const ACCESSORIES_URL = '/products/accessories/';
export const VMS_PRIVACY_URL = '/dashboard/privacy/';
export const VMS_TERMS_URL = '/dashboard/terms/';
export const VMS_NUTRITIONIST_TERMS_URL = '/dashboard/nutritionist-terms/';
export const VMS_HEALTHCOUNTER_TERMS_URL = '/dashboard/health-counter-terms/';
export const VMS_TERMS_OF_SALE_URL = '/dashboard/terms-of-sale';
export const VMS_BOOK_CALL_URL = 'https://vms-yourdaye.zohobookings.eu/#/customer/142379000000027054';
export const DR_LINDA_FARAHANI_URL = 'https://www.hcahealthcare.co.uk/finder/specialists/linda-farahani';
export const DR_SHAZIA_MALIK_URL = 'https://www.hcahealthcare.co.uk/finder/specialists/dr-shazia-malik';
export const DR_MELISSA_NASSANEY_URL = 'https://fullcirclepelvichealth.clientsecure.me/';
export const MARSHA_KENTISH_URL =
  'https://annes-day-inc.ca1.cliniko.com/bookings?business_id=1512325996424989459&practitioner_id=1512326794030614634';
export const VIENNA_FARLOW_URL = 'https://annes-day-inc.ca1.cliniko.com/bookings?business_id=1512325996424989459&practitioner_id=1512326416786524265';
export const PLAN_YOUR_BABY_URL = 'https://planyourbaby.co.uk/daye-consultations/';
export const ORIGIN_URL = 'https://www.theoriginway.com/';

export const EMAIL_VERIFIED_REDIRECT_TIMEOUT_IN_MS = 3000;
export const VMS_PHLO_EMAIL = 'pharmacy@wearephlo.com';
export const VMS_CEDARWOOD_EMAIL = 'customerservice@cedarwoodpharmacy.co.uk';
export const VMS_FEDEX_URL = 'https://local.fedex.com/en-us';
export const VMS_USPS_URL = 'https://tools.usps.com/locations/';
export const VMS_ROYAL_MAIL_URL = 'https://www.royalmail.com/services-near-you#/';
export const VMS_POST_OFFICE_URL = 'https://www.postoffice.co.uk/branch-finder';
export const VMS_RETURN_LABEL_EMAIL = 'collections@yourdaye.com';
export const HEALTH_COUNTER_TERMS_URL = '/health-counter-terms/';
export const HEALTH_COUNTER_PRIVACY_URL = '/health-counter-privacy-policy/';
export const holidayCutoffDates = {
  GBP: 'Dec 19',
  EUR: 'Dec 15',
  USD: 'Dec 12'
};

export const referralAmount = 5;
export const REQUIRE_RETEST_IF_SAMPLE_NOT_RECEIVED_DAYS = 10;

export const TRANSPARENT_1X1_IMG =
  'https://images.prismic.io/ecommerce-website/c44744dd-db70-465d-a527-77d8631a06e1_1x1-00000000.png?auto=compress,format';
export const ARTICLE_WAVE_IMG = 'https://daye.cdn.prismic.io/daye/adabfc0b-7e75-4655-a210-259a5de31bb2_wave_repeatable_1440.png';
export const BCORP_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/7afc67d8-8c31-4994-8a11-ff47a34130f7_bCorpIcon.svg';
export const SUBSCRIBE_LANDING_HERO =
  'https://images.prismic.io/ecommerce-website/4f797c41-2be6-420b-90df-d435433b6bbd_Valentina-with-Tampon-2+1-tinified.jpg?auto=compress,format';
export const SAMPLER_EXIT_POPUP_IMG =
  'https://images.prismic.io/ecommerce-website/9ee2e243-cb07-4a0b-836b-12ef1b0e0d64_Sample-box-ad-cream+1-tinified.png?auto=compress,format';
export const MELANIE_BONE_IMG =
  'https://images.prismic.io/ecommerce-website/f465c1df-b16a-414c-b46b-093a76b708f3_melanie_bone.png?auto=compress,format';

export const DASHBOARD_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/457816df-e641-4b82-9902-83c816a8b3f5_Dashboard.svg';
export const DEALS_AND_RESOURCES_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/0fba8981-63f5-460a-bfb3-aae0aacfb2c7_Deals+%26+Resources.svg';
export const PURCHASES_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/3c082a24-d30a-4660-93d9-32c07a382065_Purchases.svg';
export const MICROBIOME_SCREENING_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/79bf3e2c-f164-4ebe-8bbf-b1cb3db881cc_MicrobiomeScreening.svg';
export const TAB_FOOTER_NAV_ICON =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/4dcbc1ec-f321-4fe8-bae7-daa64718c6cb_Pain+Relief+Meds.svg';
export const ENDO_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/f455f8e6-96e3-49ba-86e2-3cb9b915a795_Endo.svg';
export const PRESCRIPTIONS_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/635f4a1e-f076-49d5-8bf7-2917af09a933_PrescriptionsNavIcon.svg';
export const CONTRACEPTION_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/b4e1830d-af4e-43d9-9eab-713ab701daaa_Contraception.svg';
export const SETTINGS_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/6115d3d2-1129-42c2-a158-618df21ca9e3_AccountSettings.svg';
export const UPGRADE_SECURITY_SVG = 'https://daye.cdn.prismic.io/daye/581a9cac-543a-4203-9c2b-c674b7740f28_upgrade-security.svg';
export const UPGRADED_SECURITY_SVG = 'https://daye.cdn.prismic.io/daye/3a9d17b1-be19-42e4-9349-63ea33f8b970_upgraded-security.svg';
export const REFERRALLS_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/e6f3f671-d6b3-48d2-acb7-ce6452d61429_Referrals.svg';

export const PROFILE_AFRO_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/0d41b8de-955f-4a6d-b119-076255529f8c_Afro.svg';
export const PROFILE_BUN_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/4e6cec9e-d6cb-4a0f-bef0-a05df80b534d_Bun.svg';
export const PROFILE_LONGHAIR_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/3521fccf-af5a-46d2-859d-9292bc35198e_Long_Hair.svg';
export const PROFILE_MEDIUMHAIR_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/8582d718-8c98-446a-bdfd-f83c92d6d815_Medium_Hair.svg';
export const PROIFLE_SHORTHAIR_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/e71f2cff-465e-428e-a5fb-bd66f6b34320_Short_Hair.svg';

export const ORGANIC_COLOR_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/b1f154bf-a56c-46b3-a9a8-bdeb4c8c2c62_organic-color.svg';
export const DOCTOR_COLOR_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ba98bdb9-f160-4027-9fa9-931c4ba61dc9_endorsed-color.svg';
export const HEART_COLOR_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/0e63a418-23b1-486c-bf4a-0fa414ec196c_heart-color.svg';

export const BRANDBASSADOR_SITE_URL = 'https://api.brandbassador.com/s/hw9mnk';
export const BRANDBASSADOR_PIXEL_URL = 'https://api.brandbassador.com/tracking/pixel.gif';

export const SCIENCE_GOGGLES_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ad92f087-4036-4a13-8b9f-e4cc9b937f25_science.svg';
export const SCIENCE_MEDS_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ab5c101a-6bc7-4be4-927e-6ac98c5276ee_cost-new.svg';

export const TIMES_LOGO_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/b53cf87e-930d-48dd-8166-a75508bd798b_times-logo.svg';
export const TIMES_LOGO_IMG =
  'https://images.prismic.io/ecommerce-website/2a7ef36e-7860-43f4-9a13-6560084dc79c_times-blue-logo+%281%29+19.10.11.png?auto=compress,format';
export const GUARDIAN_LOGO_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/e0434556-c6bf-4aea-a57f-7f767f98d849_guardian-logo.svg';
export const EVENINGSTANDARD_LOGO_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/cd1aa9f6-52b6-4b3a-ad61-7f4834369c4a_es-logo.svg';
export const VOGUE_LOGO_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/fe32fc46-4aa0-424d-8a5e-0b1dea911d2e_vogue-logo.svg';
export const WIRED_LOGO_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/5ff12145-2bd9-492b-8e8c-be55a2ddd4ea_wired-logo.svg';

export const FORBES_LOGO_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/92d7f610-746c-4ed5-8d3d-70d031f68407_Forbes-logo.svg';
export const GLAMOUR_LOGO_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZsXt1kaF0TcGJLA1_Glamour_logo.svg';
export const TECH_CRUNCH_LOGO_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/a1cbd47c-6297-4cde-9ed4-3b94b4be4f45_techcrunch-logo.svg';
export const METRO_LOGO_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/32779da8-eb33-43c0-bf40-398b73a67dfe_metro-logo.svg';

export const MARIE_CLAIRE_LOGO_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/bd082491-4e9f-4312-964a-d71e1326c332_Marie_Claire_Magazine_logo.svg';
export const HEALTHLINE_LOGO_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/e45d2e2f-ef17-4249-b607-eb29fd4e3234_Healthline_logo.svg';
export const POPSUGAR_LOGO_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/8e9418d7-5e44-4819-aea5-c771622219ae_ps-full-logo.svg';
export const REFINERY29_LOGO_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/f8b3ddb5-d074-4864-beec-a86ef1dd1140_Refinery29-text-only.svg';
export const THRILLIST_LOGO_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/4622e3be-16a6-4b10-90e8-b4c653d38d7f_thrillist-vector-logo+%281%29.svg';

export const TIMES_BLUE_LOGO_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/af9e7b67-5982-4055-8cc5-ffdd8c842a70_times-blue-logo.svg';
export const GUARDIAN_BLUE_LOGO_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/4c68ce20-85ef-4e64-b260-e2daaecb7617_guardian-blue-logo.svg';
export const EVENINGSTANDARD_BLUE_LOGO_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/0e099391-5271-48c9-aceb-f3b9cca762a9_es-blue-logo.svg';
export const VOGUE_BLUE_LOGO_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/7136c8a6-709d-48d4-85a5-ddf56331b959_vogue-blue-logo.svg';
export const WIRED_BLUE_LOGO_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/53b16bdc-566d-4d94-b339-6c48f6852cec_wired-blue-logo.svg';

export const ELVIE_BLUE_LOGO_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/49de7433-a51b-45c8-8188-60a7aa438e7a_elvie_logo.svg';
export const WILD_NUTRITION_BLUE_LOGO_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/c156e7fa-003b-47c4-adb4-66c2d0f85059_wild-nutrition-logo.svg';
export const SKIN_ME_BLUE_LOGO_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ebca32c5-ef1b-47e4-b064-f131f9f5a70d_skin-me-logo.svg';
export const MYOOVI_BLUE_LOGO_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/602f8780-bae2-463c-8120-0127c43a9f2d_myoovi-logo-2.svg';

export const DAYE_TIN_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/124f32f7-156a-4f77-b3d2-f01d66e06108_daye-tin.svg';
export const DAYE_MONOGRAM_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/b3448556-8e68-41a9-b2b0-2b5d3e1def8b_daye-monogram-new.svg';
export const DAYE_EIC_IMG =
  'https://images.prismic.io/ecommerce-website/0917078c-7da5-4a46-a24b-0232f592114a_EIC-logo-CoFundedBy-WhiteText_EN+2%402x.png?auto=compress,format';

export const ABSORBENCY_SUPER_GRAPH_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/9aa1685f-546b-4088-832b-4f5114a2d890_absorbency-super.svg';
export const ABSORBENCY_REGULAR_GRAPH_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/a9446f49-f2b1-4caa-940b-6dbc89f070a8_absorbency-regular.svg';

export const TAMPON_COMPARISON_NAKED =
  'https://images.prismic.io/ecommerce-website/f0c37eae-c1b8-431f-b802-a3b56830ca31_tampon-comparison-naked-tinified.png?auto=compress,format';
export const TAMPON_COMPARISON_COATED =
  'https://images.prismic.io/ecommerce-website/4343885c-8310-4a5d-a062-8b8218bcb450_tampon-comparison-coated-tinified.png?auto=compress,format';

export const PAD_COMPARISON_REGULAR =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/2e0bb556-074f-47f8-9a73-5ef8dab6ce13_Regular-pad.svg';
export const PAD_COMPARISON_SUPER = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/89c1db86-9b52-4c00-b574-98ccadaea5da_Super-pad.svg';
export const PAD_COMPARISON_SUPER_PLUS =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/aebccc26-f892-47f6-b562-bd71ac33f39a_Night-plus-pad.svg';
export const PAD_COMPARISON_LINER = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/544d878d-f919-4931-a444-09d2f309c4bb_IconLiners.svg';
export const PAD_BIODEGRADE_DAYS = 142;
export const PAD_COMPOST_DAYS = 152;
export const PAD_TYPES = {
  REGULAR: {
    LENGTH_MM: 240,
    LENGTH_CM: 24,
    ABSORBENCY_ML: 200
  },
  SUPER: {
    LENGTH_MM: 285,
    LENGTH_CM: 29,
    ABSORBENCY_ML: 235
  },
  SUPER_PLUS: {
    LENGTH_MM: 350,
    LENGTH_CM: 35,
    ABSORBENCY_ML: 325
  },
  LINER: {
    LENGTH_MM: 155,
    LENGTH_CM: 16,
    ABSORBENCY_ML: 10
  }
};
export const SUPPLY_CHAIN_PACK_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/58ccc38f-3a49-4da2-ae99-04a53aa88dc9_pack.svg';
export const SUPPLY_CHAIN_THREAD_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/e70e81d5-1a83-4583-84a9-72de1e52479d_thread.svg';
export const SUPPLY_CHAIN_TEST_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/c4b31c62-9bab-4d33-9e78-7650fad9474c_test.svg';

export const COMPARISON_TABLE_DAYE_LOGO =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/00a7e4d3-f08d-4b97-8554-998e0ddde84e_logo_daye.svg';
export const PROVIOTICS_JAR_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/5890f4bd-0ff2-4110-a3de-c3698946ad8d_proviotics+packaging+icon.svg';
export const PROVIOTICS_MAILER_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/363e48f0-4594-43e7-ba32-736a1463e3d6_pv-box-and-mailer.svg';
export const PROVIOTICS_POUCH_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/33361d33-a00e-4c6b-9354-fc8166cdaddf_pv-cotton-pouch.svg';
export const PROVIOTICS_APPLICATOR_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/c8e6632d-2b9b-48e9-a52e-76d18de91dd3_pv-applicator.svg';
export const PROVIOTICS_REFILL_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/0b6011d8-95a0-420a-8de4-32a3fc4558b1_pv-refill-pouch.svg';
export const PROVIOTICS_HEADERICON1_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/2600b7b4-4156-4a87-adff-b74e8217f198_header-icon1.svg';
export const PROVIOTICS_HEADERICON2_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ee09269f-a97b-4459-a7c0-2fb56eef9f03_header-icon2.svg';
export const PROVIOTICS_HEADERICON3_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/f29e1897-191e-4fd5-812c-60f518d026f6_header-icon3.svg';

export const PARTNERSHIP_EVENTS_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/bbf9570e-3bc3-46be-9ef8-c45c980ca0cd_icon-events.svg';
export const PARTNERSHIP_IMPACT_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/a1c5fe2f-f4ac-4a4b-856a-4c0ab9a4b7d6_icon-impact.svg';
export const PARTNERSHIP_COLLABORATIONS_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/3fefb7f4-8723-4bb0-9565-78c76d1c1d32_icon-collaborations.svg';

export const FACEBOOK_LOGO_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/e23ed1a9-ece8-4b71-b782-604fa174eda4_logo-facebook.svg';
export const LIBERTY_LOGO_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/76d1661c-a2ce-4c97-95c7-bc01585c58d0_logo-liberty.svg';
export const META_LOGO_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/3a0aa7d7-adee-4243-9dba-fdc128833883_logo-meta.svg';
export const MONZO_LOGO_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/762c0513-b778-4859-9d20-e199e3a7002f_logo-monzo.svg';
export const OLIVERBONAS_LOGO_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/0dce6a11-c822-4424-a46b-9daf07ead80d_logo-oliver-bonas.svg';
export const PLANETORGANIC_LOGO_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/db46d8df-3b09-45d4-8618-bc87b8880e4a_logo-planet-organic.svg';

export const NEW_DAYE_DIVIDER_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/0595b1b0-74f0-4cb1-953e-6238fcec4858_new-daye.svg';
export const TEAM_DAYE_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/d14bc45a-896e-4316-a912-c9700c9998b8_team-daye.svg';
export const NINETY_PERCENT_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/cf98b940-26c2-4d92-b576-07cd3a121b7d_ninety-percent.svg';

export const USP_SUSTAINABLE_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/09bc6655-48ad-4c01-ab03-ee9cbf661330_usp-sustainable.svg';
export const USP_SUBSCRIPTION_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/c6c39819-9768-4ff3-85e0-59bae38d70a2_usp-subscription.svg';
export const USP_SANITISED_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/f29a0dd3-db23-4dc0-aba3-00cf60e9bbde_usp-sanitised.svg';
export const USP_ORGANIC_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/e9a10524-fc16-4726-9d13-b6c0d3c74258_usp-organic.svg';
export const USP_CRAMP_SOOTHING_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/40007b45-d278-48dc-b360-3b0bfe1851fe_usp-cramp-soothing.svg';

export const VESICA_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/77003814-64ca-478b-8834-5499b008b877_vesica.svg';
export const DAYE_LOGO_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/bbb702e6-bccc-42cc-8dbc-30f9c41824c7_daye-logo-forest-green.svg';

export const AMBASSADOR_CREDIT_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/b6bdcddb-197d-4036-af62-400ad2ba5616_ambassador-credit.svg';

export const MENU_REFERRALS_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/dcc20f78-5ad0-4edf-adb8-d93fccdd317a_icon-referrals.svg';
export const MENU_SUBSCRIPTIONS_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/a4ee3b5b-e62f-4269-ba26-26d4eddfa0f7_icon-subscriptions.svg';
export const MENU_DETAILS_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/fc1055e3-2762-4570-b0f6-ceebe8fef707_icon-details.svg';
export const MENU_ORDERS_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/6ee0afd2-900a-4537-a86e-7de642b80f7b_icon-orders.svg';
export const MENU_GIFT_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/572a6c37-dc2e-4f7c-99f4-49c32ff42598_icon-gift.svg';

export const PADS_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/965bd2af-7c2a-4413-855f-f829d7e88aeb_daye-pads.svg';
export const PADS_IMAGE = 'https://images.prismic.io/ecommerce-website/8a7a5b23-c53f-4c6b-9421-9c40422f240d_pads-image.png?auto=compress,format';
export const PADS_ICON_SVG = 'https://images.prismic.io/ecommerce-website/9c2bcb38-59b0-4015-bd00-2f132600c10c_pads_icon.png?auto=compress,format';
export const PADS_QUALITY_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/2a61ee1b-655b-443b-b937-888bc9b0e02e_icon-quality.svg';
export const PADS_PACKAGING_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/d23bfaf8-2090-4eb2-8280-e4d359a8a051_icon-bio-packaging.svg';
export const PADS_BIODEGRADABLE_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/5b55b975-0be9-4bef-8eb2-1f6e2cb8de04_icon-biodegradeable.svg';
export const PADS_COMFORT_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/9fb695d9-c2bd-49e4-91e5-e06dcb5f8edc_icon-comfort.svg';
export const PADS_ABSORBENCY_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/295e1652-ba31-4093-9d9e-5054cde8d2cc_icon-hyper-absorbent.svg';

export const GIFT_A_FRIEND_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/b6d8ff84-261c-441b-8060-2c56d7124e85_friendAlt.svg';
export const APPLE_BUTTON_URL = 'https://images.prismic.io/ecommerce-website/ZwP0MoF3NbkBW-tZ_SigninwithApple.png?auto=format,compress';
export const APPLE_BUTTON_SIGNIN_URL =
  'https://images.prismic.io/ecommerce-website/e4d91c4c-dfcf-4c29-a517-eaebad76212e_Sign+in+with+Apple.png?auto=compress,format';

export const TICK_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/b790abea-97c6-4c19-8a2c-8ad0440c316b_tick.svg';
export const WARNING_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/056b8369-972f-429e-aae3-bc7c577ae446_icon-warning.svg';
export const SOLID_TICK_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/bae242bc-4fcb-45b0-b4ae-6dbd9cdf8489_solid_tick.svg';
export const SOLID_CROSS_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/e285bc8a-f831-4879-87a2-4d1f9fb4bcec_remove.svg';
export const EXTERNAL_LINK_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/c07c8e5e-8b2b-460c-a629-a8e49e07f612_link_img.svg';
export const TAMPON_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/575f7166-23cf-4859-bc9d-5be0bb546106_Tampon+Icon.svg';
export const CALENDAR_ICON_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/1598b368-408a-421d-9a4d-5c6f4c75fc4b_calendarIcon.svg';
export const CHECKBOX_TICK_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/c56c3e5b-8c33-402d-87e3-90ca73145387_Check.svg';
export const HEADER_CLOSE_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/a164f060-40ab-4368-8653-4da81e434a06_header_close.svg';
export const CHEVRON_LEFT_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/a82146e0-dc1a-4097-8266-bea706017bc4_icon-chevron-left.svg';

export const KLAVIYO_PADS = 'TfJNWb';
export const KLAVIYO_PADS_US = 'Ypdzgq';
export const KLAVIYO_VMS = 'WK9j5B';
export const KLAVIYO_STI = 'TMSQPD';
export const KLAVIYO_HPV = 'Y8CprN';
export const KLAVIYO_CBD_BALM = 'Yf9zMR';
export const KLAVIYO_ENDO = 'Tj5w2s';
export const KLAVIYO_PCOS = 'U7Wjnd';
export const KLAVIYO_CRE = 'Yr3Dpy';

export const VMS_TOOLTIP_ICON_SVG =
  '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '<rect x="0.75" y="0.75" width="18.5" height="18.5" rx="9.25" stroke="#001D3B" stroke-width="1.5"/>\n' +
  '<path d="M8.41403 13.89C8.41403 14.576 8.94603 15.08 9.63203 15.08C10.318 15.08 10.864 14.576 10.864 13.89C10.864 13.204 10.318 12.686 9.63203 12.686C8.94603 12.686 8.41403 13.204 8.41403 13.89ZM8.76403 7.534C8.80603 7.128 9.15603 6.68 9.77203 6.68C10.374 6.68 10.78 7.03 10.78 7.548C10.78 8.654 8.54003 8.976 8.54003 10.782C8.54003 11.566 9.03003 12.126 9.18403 12.308L10.598 11.328C10.472 11.202 10.36 11.062 10.36 10.824C10.36 9.984 12.6 9.578 12.6 7.52C12.6 6.078 11.354 5 9.77203 5C8.24603 5 7.25203 5.966 7.00003 7.072L8.76403 7.534Z" fill="#001D3B"/>\n' +
  '</svg>';
export const VMS_INSIGHT_TICK_ICON_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/931eae7d-4b9f-4063-965c-519084f35a13_vms-insight-tick.svg';
export const VMS_ENABLE_FEATURES_GATE_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/e8cb6e8f-ac88-4f64-a430-48113b84bda7_enableFeaturesGate.svg';
export const HEMP_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/533ad684-5ba9-4ca0-a18b-358c6585c130_hemp-leaf+%281%29.svg';
export const VMS_KIT_IMG = 'https://images.prismic.io/ecommerce-website/Zgfejst2UUcvBSi5_STI-test-front-no-ornaments1.png?auto=format,compress';
export const VMS_FOUNDERS_RESULTS_IMG =
  'https://images.prismic.io/ecommerce-website/cde1071d-02e1-4b50-a508-132404993091_founders-results-tinified.png?auto=compress,format';
export const VMS_FOUNDERS_RESULTS_NEW_IMG =
  'https://images.prismic.io/ecommerce-website/ZfHaK0mNsf2sHjt4_founders-results-new.png?auto=format,compress';
export const VMS_SPECIALIST_CARE_IMG =
  'https://images.prismic.io/ecommerce-website/2d5567d6-225c-4b79-8e64-95d00979e500_specalist-care-img-tinified.png?auto=compress,format';
export const VMS_SPECIALIST_CARE_IMG_NO_BACKGROUND =
  'https://images.prismic.io/ecommerce-website/a5ca0d87-0cee-4189-8e59-3e98d54ac83e_specialist-care-no-background.png?auto=compress,format';
export const VMS_VAGINAL_FLORA_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/59cde5e3-2903-4465-94a4-fe48ec22778d_vms-flora-petri-dish.svg';
export const VMS_LACTOBICILLI_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/356a68a4-a2a3-4f89-9940-c9ec34edcda7_lactobicilli.svg';
export const VMS_QUESTION_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/74ee326d-d28a-4751-9899-5200b35a14e0_vms_question_icon.svg';
export const VMS_PHYSICIANS_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ad522ddb-881d-409d-896c-ac5bb0213ba2_vms_physicians_icon.svg';
export const VMS_TREATMENTS_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/8698c273-e4a8-40e1-8d4d-71c141fb2632_vms_treatments_icon.svg';
export const VMS_CONTRACEPTION_HEADER_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/c3410420-9f63-4286-bad9-8e87f68a7b80_contraception.svg';
export const VMS_PLATFORM_IMG =
  'https://images.prismic.io/ecommerce-website/46a91753-8fcb-45c4-a98c-0858aa587b34_daye-platform-phone-sm+1.png?auto=compress,format';
export const VMS_PCOS_HEADER_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/eeed7877-0e57-4979-a886-84fb46186d86_pcos%402x+1.svg';
export const PPC_CLIPBOARD_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/c1826f51-b3ee-4298-8929-5aa39311f535_PpcClipboard.svg';
export const PASSWORDLESS_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/2a2ba3a7-2f5b-4bec-a47a-babfd7c2ad38_Passwordless.svg';
export const VMS_MICROBIOME_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/bee563e3-8d2f-4f6a-85e6-273ca08ca488_meetmicrobiome.svg';
export const VMS_KIT_SCREENING_IMG =
  'https://images.prismic.io/ecommerce-website/e3ce2dcd-deb0-47ed-951b-661a3c20362c_vms-kit-tinified.png?auto=compress,format';
export const PPC_PAIN = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/2cae7075-359e-4fad-8892-c85f0c162000_ppc-pain.svg';
export const PPC_PAINKILLERS = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/119851c3-bbb2-4965-8557-4835792fea63_ppc-painkillers.svg';
export const PPC_DIAGNOSIS = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/37ce3d12-b8d7-496b-90ae-4a39dc06225d_ppc-diagnosis.svg';
export const DR_DONIERT_MCFARLANE_IMG =
  'https://images.prismic.io/ecommerce-website/1aa9678f-0d8b-4ad4-ba48-4cfe5f4f747f_DrDoniertMacfarlane-tinified.png?auto=compress,format';
export const DR_SHAZIA_MALIK_IMG = 'https://images.prismic.io/ecommerce-website/ZjySO0MTzAJOCpmh_Shazia-2024.webp';
export const NATASHA_EVANS_IMG = 'https://images.prismic.io/ecommerce-website/ZjySOUMTzAJOCpme_Natasha-2024.webp';
export const KATHRYN_LEVY_IMG =
  'https://images.prismic.io/ecommerce-website/6b8a14c2-659a-488a-8b3f-c389376eb0a9_KathrynLevy.png?auto=compress,format';
export const ALISHA_MCINTYRE_IMG =
  'https://images.prismic.io/ecommerce-website/1cb0b00d-5d34-4d10-9e98-ed6ee8cb529f_AlishaMcIntyre.png?auto=compress,format';
export const SARAH_MONTAGU_IMG = 'https://images.prismic.io/ecommerce-website/ZjySOkMTzAJOCpmg_Sarah-2024.webp';
export const CLARE_MELLON_IMG = 'https://images.prismic.io/ecommerce-website/ZjySNUMTzAJOCpma_ClaireMellon-2024.webp';
export const ZOC_DOC_IMG = 'https://images.prismic.io/ecommerce-website/35cc031d-1dd1-4188-b897-ffe5d9acb1d3_zoc-doc.png?auto=compress,format';
export const DR_LINDA_FARAHANI_IMG = 'https://images.prismic.io/ecommerce-website/ZjySN0MTzAJOCpmc_Linda-2024.webp';
export const DR_GEORGINA_LESLIE_IMG =
  'https://images.prismic.io/ecommerce-website/e653d3bd-2a80-4b55-8a56-8ad4b1e57704_Dr_Georgina_Leslie.png?auto=compress,format';
export const DR_GEORGINA_LESLIE2_IMG =
  'https://images.prismic.io/ecommerce-website/d81f72ce-0717-4d4c-940d-3fed166d5bba_Dr_Georgina_Leslie2.png?auto=compress,format';
export const JULIE_WALSH_IMG =
  'https://images.prismic.io/ecommerce-website/73fa2d94-4c58-41f4-af0b-744d4e5227ad_Julie+Welsh%402x.png?auto=compress,format';
export const KELSIE_HENSCHEL_IMG =
  'https://images.prismic.io/ecommerce-website/8f38ff61-9844-4ee2-90a9-8a8b820eead5_Kelsey-Henschel%402x.png?auto=compress,format';
export const MARSHA_KENTISH_IMG = 'https://images.prismic.io/ecommerce-website/ZjySOEMTzAJOCpmd_Marsha-2024.webp';
export const MELISSA_NASSANEY_IMG = 'https://images.prismic.io/ecommerce-website/ZsRcQ0aF0TcGJGe9_Melissa-2024.webp';
export const DEBORAH_MAQUIRE_IMG = 'https://images.prismic.io/ecommerce-website/ZjySNkMTzAJOCpmb_Deborah-2024.webp';
export const VIENNA_FARLOW_IMG = 'https://images.prismic.io/ecommerce-website/ZyNV7q8jQArT0DXr_Vienna-2024.webp';
export const PLAN_YOUR_BABY_IMG = 'https://images.prismic.io/ecommerce-website/ZyPt568jQArT0GT3_PYB-2024.webp';
export const ORIGIN_IMG = 'https://images.prismic.io/ecommerce-website/ZyPuS68jQArT0GUJ_Origin-2024.webp';

export const VMS_TICK_GREEN_IMG = 'https://images.prismic.io/ecommerce-website/ZjDRtN3JpQ5PTRDP_Deborah160.png?auto=format,compress';
export const VMS_TICK_GOLD_IMG = 'https://images.prismic.io/ecommerce-website/36b7ec82-3e23-4b4f-8e3d-2b7f728d3000_GoldTick.png?auto=compress,format';
export const VMS_EXCLAMATION_PURPLE_IMG =
  'https://images.prismic.io/ecommerce-website/0d2c3344-682e-4d9c-b325-09d7de7c0f9b_ExclamationPurple.png?auto=compress,format';
export const VMS_EXCLAMATION_YELLOW_IMG =
  'https://images.prismic.io/ecommerce-website/3e4d49fd-61ea-47b0-9716-7e8fda5c9176_ExclamationYellow.png?auto=compress,format';
export const VMS_YELLOW_TICK_IMG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/c5b250d2-e072-4666-8552-157f8fbe627a_yellow-tick.svg';
export const VMS_NORMAL_TICK_IMG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/781e7e77-65b7-4774-88d5-57d55c9280ed_normal-tick.svg';

export const ARROW_DOWN = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/6b1ca7cb-929c-4147-b94f-a4cfa6b93e9d_arrow-down.svg';

export const STUDENT_BEANS_IFRAME_URL = 'https://connect.studentbeans.com/v4/daye/uk';

export const BOOKLET_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZjtMM0MTzAJOCoPZ_Booklet.svg';
export const PERIOD_PAIN_CONSULT_TRIALS_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/996a90c9-b7a3-4ac9-a1bc-65fae8af26c4_Trials.svg';
export const PERIOD_PAIN_CONSULT_RINGS_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/4ccea22b-a622-4aea-961d-fa20af81561a_rings.svg';
export const PERIOD_PAIN_LINE_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/b9260b48-355d-4f30-ac65-20df468f67a4_Period_Pain-line-final+1.svg';
export const PERIOD_PAIN_IMG =
  'https://images.prismic.io/ecommerce-website/e9d5ecd1-2388-4545-8c82-5907914f684f_period-pain.png?auto=compress,format';
export const VAGINA_MATTERS_BOOK_IMG =
  'https://images.prismic.io/ecommerce-website/99224879-1a24-4406-b702-542b70f80a80_VaginaMatters-sm-tinified.png?auto=compress,format';

export const NATASHA_EVANS_BOOKING_URL = 'https://vms-yourdaye.zohobookings.eu/#/customer/142379000000027138';
export const PPC_NURSE_CONSULTATION_URL = 'https://vms-yourdaye.zohobookings.eu/#/customer/142379000000221112';
export const PPC_NUTRITIONIST_CONSULTATION_URL = '/dashboard/book-a-call/';
export const SEXUAL_HEALTH_NURSES_BOOKING_URL = 'https://vms-yourdaye.zohobookings.eu/#/customer/142379000000027152';
export const SEXUAL_HEALTH_NURSES_PREPAID_BOOKING_URL =
  'https://daye-health-platform.uk2.cliniko.com/bookings?business_id=1244819784374165565&practitioner_id=1244845869899981457&appointment_type_id=1244855137399218199#service';
export const SEXUAL_HEALTH_NURSES_FERTILITY_PREPAID_BOOKING_URL =
  'https://daye-health-platform.uk2.cliniko.com/bookings?business_id=1244819784374165565&practitio[…]8755319955&appointment_type_id=1244857954100846617';
export const US_SEXUAL_HEALTH_NURSES_BOOKING_URL = 'https://annes-day-inc.ca1.cliniko.com/bookings?business_id=1512325996424989459';
export const US_SEXUAL_HEALTH_NURSES_PREPAID_BOOKING_URL = 'https://annes-day-inc.ca1.cliniko.com/bookings?business_id=1512325996424989459';
export const GP_BOOKING_URL = 'https://vms-yourdaye.zohobookings.eu/#/customer/142379000000027054';
export const ZOCDOC_URL = 'https://www.zocdoc.com/obgyns';

export const PARTNER_TREATWELL_IMG =
  'https://images.prismic.io/ecommerce-website/7511a355-65e4-4053-b813-1c56fa6c8b73_Service%3DTreatwell.png?auto=compress,format';
export const PARTNER_LOWDOWN_IMG =
  'https://images.prismic.io/ecommerce-website/83ff7aca-0e5b-4303-aa96-e79a6e9c7a85_Service%3DLowdown+Contraception.png?auto=compress,format';
export const PARTNER_LASERCLINICS_IMG =
  'https://images.prismic.io/ecommerce-website/9f51e553-52bb-4deb-bcf7-5730bea80e63_Service%3DLaser+Clinics.png?auto=compress,format';
export const PARTNER_ENDOMETRIOSISUK_IMG =
  'https://images.prismic.io/ecommerce-website/9890314c-62ea-4989-a4b5-5ac3b1e0ba44_Service%3DEndo+UK.png?auto=compress,format';
export const PARTNER_WILDNUTRITION_IMG =
  'https://images.prismic.io/ecommerce-website/2eab42ae-d76e-4474-85e1-12e00ec9aedd_Product%3DWild+Nutrition+Supplements.png?auto=compress,format';
export const PARTNER_SKINANDME_IMG =
  'https://images.prismic.io/ecommerce-website/730eba43-51f9-4c8b-b77c-d98399e174fb_Product%3DSkinMe+Acne+Care.png?auto=compress,format';
export const PARTNER_SENS_IMG =
  'https://images.prismic.io/ecommerce-website/bfd839b6-6483-4d1c-8746-84db62ca86b9_Product%3DSens+Heating+Pads.png?auto=compress,format';
export const PARTNER_PELVICDANCEFLOOR_IMG =
  'https://images.prismic.io/ecommerce-website/5beaf5a2-4a21-4897-943d-df4f63e35eac_Product%3DPelvic+Dance+Floor.png?auto=compress,format';
export const PARTNER_MYOOVI_IMG =
  'https://images.prismic.io/ecommerce-website/2c1882b2-a44d-4e0d-a1da-5194511fa9e9_Product%3DMyoovi+TENS+Machines.png?auto=compress,format';
export const PARTNER_HANX_LUBE_IMG =
  'https://images.prismic.io/ecommerce-website/7d93a961-540d-441d-bb49-738374fc9c42_Product%3DHanx+Lube.png?auto=compress,format';
export const PARTNER_HANX_CONDOMS_IMG =
  'https://images.prismic.io/ecommerce-website/7a382366-de82-4428-b859-6b76073d5761_Product%3DHanx+Condoms.png?auto=compress,format';
export const PARTNER_ZOCDOC_IMG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/f369d55c-a31c-458e-901c-78f193844d4f_ZocDoc-logo.svg';
export const PARTNER_PLANNEDPARENTHOOD_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/293f6c45-8fdc-4335-b1e1-8e466771fdcb_PlannedParenthood.svg';
export const PARTNER_FREESTYLE_IMG =
  'https://images.prismic.io/ecommerce-website/615b1a6f-b0f4-48e8-bf38-3b7ffcc562a4_Product%3DAbbott+Libre+Glucose+Monitoring.png?auto=compress,format';
export const SERVICE_NHS_IMG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/5736dc29-61b6-428a-9123-a14395b8a478_Service%3DNHS.svg';
export const CONSULTATION_CLINIC_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/1153901b-6758-48c9-b4bc-63ce93d4da9f_Type%3DIRL+Clinic.svg';
export const CONSULTATION_NUTRITIONIST_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/1ed894b8-3b40-4852-9d52-a88f88d12c88_Type%3DNutritionist.svg';
export const CONSULTATION_NURSE_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/4c5a04c4-7eb5-45e4-b3eb-489d50b62e3d_Type%3DNurse.svg';
export const CONSULTATION_FERTILITY_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/4c209636-5136-4fd4-9db7-ef7a0dc56489_Type%3DFertility+Specialist.svg';
export const CONSULTATION_PHYSICAL_THERAPY_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/76f4498c-a71f-4beb-b024-6b7c06748b24_Type%3DPhysical+Therapist.svg';
export const CONSULTATION_EXPORT_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/fb1728ed-9d13-4fbe-a51d-b40540dfed04_Type%3DExport.svg';
export const CONSULTATION_CLAREMELLON_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/107b0c36-23c1-4d1b-884f-31d5fbfb03a5_Type%3DClaire+Mellon.svg';

export const VIDEO_PLAY_BUTTON = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/78dc5eba-6820-47de-8117-8441a5a3d73c_video-icon.svg';
export const VIDEO_PLAY_BUTTON_2 = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/f6be5686-c1fa-4b28-91f3-b10b59aa50f9_video-play.svg';

export const SCREENING_SURENGE_BIOME_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/8172a048-1596-46dc-8f76-86395822eab8_suringe-biome.svg';
export const SCREENING_SCREENING_HEART_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/a709a060-2a6e-4481-b49d-ebf973d334fb_screening-heart.svg';
export const SCREENING_PATIENT_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/8bfef615-029e-4341-8925-cddafce2d779_icon-patient.svg';
export const SCREENING_NOT_EMERGENCY_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/f1a5a23a-8951-47c6-ad93-435ca8d63bfe_pink-warning.svg';
export const SCREENING_SYMPTOM_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/b390eaed-3796-4f18-9097-46a6db8cf0ed_symptom-icon.svg';
export const SCREENING_SYMPTOM_CHECKED_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/4c881991-a300-412a-8d69-ead7fa157778_symptom-you-are-presenting.svg';
export const SCREENING_SYMPTOM_UNCHECKED_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/80f0dae6-3067-40ce-8cdd-4708f45f70f2_sypmtom-to-look-out-for.svg';
export const SCREENING_TREATMENT_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/f9321f4c-9018-4630-9d2d-2956d2786d78_icon-sm-pills.svg';
export const SCREENING_TAMPON_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/d8f7b1a7-bdb1-4c5a-a4cf-c3a6b5f64b0a_Type%3DTampon.svg';
export const SCREENING_STI_IMG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/d9d4350f-e650-45ff-860b-2ae51a4dd5e9_Type%3DSTI.svg';
export const SCREENING_SUMMARY_IMG =
  'https://images.prismic.io/ecommerce-website/006285af-d18e-4fc8-84b0-86c6c98d2c6f_greeting-women.png?auto=compress,format';
export const SCREENING_SUMMARY_MOBILE_IMG =
  'https://images.prismic.io/ecommerce-website/50a752e0-fcb8-4441-a5c0-adcac161b2fa_greeting-women-mobile.png?auto=compress,format';
export const SCREENING_SUMMARY_CLEAR_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/2eff84c7-aff9-462a-b6e6-26e3f874c259_smiling-emoji.svg';
export const SCREENING_SUMMARY_STI_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/6a5f3224-e406-4e4b-8cd3-3292804b4c54_sti-simple.svg';
export const SCREENING_SUMMARY_VMS_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/bef78622-6503-4353-9b97-8b1a14d7901b_microbiome-simple.svg';
export const SCREENING_SUMMARY_NOT_ALONE_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/94b76dc8-5a6e-46d9-90e7-b6c17b0d3125_wave-hand.svg';
export const SCREENING_SUMMARY_REQUEST_TP_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ee3c6250-2487-4115-bd01-c64878909129_request-tp.svg';
export const SCREENING_SUMMARY_SPECIALIST_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/e4c3c025-6772-4b85-b518-4df29b35cfea_speak-to-specialist.svg';
export const SCREENING_SUMMARY_CLINIC_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/c66cc4ad-712f-4984-acb5-faeaf298589e_clinic.svg';
export const SCREENING_SUMMARY_PARTNER_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/9fbe9927-fb69-4089-9394-508e3154aa18_notify-partner.svg';
export const SCREENING_SUMMARY_FIND_OUT_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/c5720174-6db4-4dff-adea-ac556d62edee_find-out.svg';
export const SCREENING_SUMMARY_SAFE_SEX_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/79fe1fe4-1b74-4f2f-ba00-e8227c9dfa29_stay-safe.svg';
export const SCREENING_SUMMARY_TUBE_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/87922de5-9b82-4ef0-8df9-769f2efe8cc0_lab-tube.svg';
export const SCREENING_SUMMARY_LIFEHACKS_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/6d92e3fc-142d-413d-a985-03afd69f613f_lifestyle-hacks.svg';
export const SCREENING_SUMMARY_THRIVING_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/fae55dc3-0a02-4066-bec5-eb16b7314529_thriving-vagina.svg';
export const SCREENING_SUMMARY_NUTRITION_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/e8ce4eef-9348-4780-86f9-d5960d5efb43_nutritionists.svg';
export const SCREENING_SUMMARY_NURSES_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/453eba04-57ba-4030-b638-c6559f86abc6_sex-health-nurses.svg';
export const SCREENING_SUMMARY_INFECTIONS_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/85fb26b5-e0cb-41d8-a572-504b5912a4da_recurrent-infection.svg';
export const SCREENING_SUMMARY_FERTILITY_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/081b5e50-aa03-44fc-857b-aa295757abb7_fertility.svg';
export const SCREENING_SUMMARY_CONRACTING_STI_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/94b902a9-7859-4111-ae5f-b7fd39a6a40b_contracting-sti.svg';
export const SCREENING_SUMMARY_LUBE_IMG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/761ddfa6-9db5-4bd1-a647-48754e35a4ce_lube.svg';
export const SCREENING_REFFERAL_IMG =
  'https://images.prismic.io/ecommerce-website/6d361dfa-6bd9-47fe-a2a6-278b13139759_refferal-screening.png?auto=compress,format';
export const SCREENING_EASILY_TREATED =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/b4abada8-4e31-463e-ab70-a930bca1e1c5_deals-and-resources.svg';
export const SCREENING_RETEST_IMG =
  'https://images.prismic.io/ecommerce-website/17943cb2-e190-42ec-b23c-35dcec03b48b_icon-product-dt.png?auto=compress,format';
export const SYMPTOM_LACTOBACILLUS_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/e3d84f96-893b-467e-adc8-9fa00937807f_lactobacilli.svg';
export const SYMPTOM_LACTOBACILLUS_COLOR_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZfbdFcmUzjad_Tq6_vms-flora-lactobacilli.svg';
export const SYMPTOM_CANDIDA_IMG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/b166787e-ed5c-4d54-a930-1ae174629e07_candida.svg';
export const SYMPTOM_CANDIDA_COLOR_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ad19d0d7-0e2c-4804-99a1-d64aaadf1602_vms-flora-fungi.svg';
export const SYMPTOM_ANAEROBIC_MICROORGANISMS_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/1ccec02c-575f-4d48-aa65-1deb1007022a_anaerobic-+bacteria.svg';
export const SYMPTOM_ANAEROBIC_MICROORGANISMS_COLOR_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/9c56a09e-2ad0-42aa-8b65-e7078f46f528_vms-flora-bacteria.svg';
export const SYMPTOM_MYCOPLASMA_HOMINIS_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/40b43163-6072-427c-8a4f-7af1f76635f3_plasma.svg';
export const SYMPTOM_MYCOPLASMA_HOMINIS_COLOR_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZiE3MPPdc1huKnNh_mycoplasma-h_icon.svg';
export const SYMPTOM_MYCOPLASMA_UREAPLASMA_COLOR_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZfbdFsmUzjad_Tq7_vms-flora-mycoplasmas.svg';
export const SYMPTOM_UREAPLASMA_IMG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/40b43163-6072-427c-8a4f-7af1f76635f3_plasma.svg';
export const SYMPTOM_UREAPLASMA_COLOR_IMG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZiE3aPPdc1huKnNn_ureaplasma_icon.svg';
export const SYMPTOM_CHLAMYDIA_IMG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/78f68ca4-3359-4960-bdd4-7209f4998573_chlamydia.svg';
export const SYMPTOM_CHLAMYDIA_COLOR_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/b3723fe2-5c66-4591-ae8c-3a6d56481ba6_sti-clamydia-icon.svg';
export const SYMPTOM_GONORRHOEA_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/a6160bce-668d-4f0a-bab8-af8ba19c5ec3_gonnorhoea.svg';
export const SYMPTOM_GONORRHOEA_COLOR_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/88db3621-5e85-41c2-96bd-3950f9e9786a_sti-gonorrhea-icon.svg';
export const SYMPTOM_TRICHOMONIASIS_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/af167fac-1c18-4e30-a863-edc311da0c2f_trichomoniasis.svg';
export const SYMPTOM_TRICHOMONIASIS_COLOR_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/8b38c157-3d0b-4c1d-bb9a-2f0f85ad1bbd_sti-trichomoniasis-icon.svg';
export const SYMPTOM_HERPES_COLOR_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/fe916cc0-d535-4338-b21c-9aef15208a17_sti-herpes-icon.svg';
export const SYMPTOM_HPV_COLOR_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/89f99f5c-7f10-4cc9-9287-8c8d8d7985c8_sti-hpv-icon.svg';
export const SYMPTOM_MYCOPLASMA_GENITALIUM_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/40b43163-6072-427c-8a4f-7af1f76635f3_plasma.svg';
export const SYMPTOM_MYCOPLASMA_GENITALIUM_COLOR_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZfbdFsmUzjad_Tq7_vms-flora-mycoplasmas.svg';
export const SYMPTOM_MYCOPLASMA_COLOR_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/a275c7ed-e1bb-4074-ba1f-6daa8ae4ecba_sti-myscoplasma-icon.svg';

export const LEARN_MORE_PROVIOTICS_IMG =
  'https://images.prismic.io/ecommerce-website/cbf8910c-e6e3-40a9-bfc4-40acb74643b9_proviotics-learn-more.png?auto=compress,format';
export const LEARN_MORE_TAMPONS_IMG =
  'https://images.prismic.io/ecommerce-website/0329fe90-0f52-4408-9dad-cd9ef825a217_tampons-learn-more.png?auto=compress,format';

export const PRODUCT_PROVIOTICS_IMG =
  'https://images.prismic.io/ecommerce-website/29b97dc4-14a0-4bc8-ba58-2040b4b6794c_proviotics_60.png?auto=compress,format';
export const PRODUCT_PROVIOTICS_IMG_2 =
  'https://images.prismic.io/ecommerce-website/4e7591e6-d4c1-4d8c-a93c-41b458c6eb90_proviotics-60-2.png?auto=compress,format';
export const PRODUCT_TAMPONS_IMG =
  'https://images.prismic.io/ecommerce-website/29dea378-b022-4b65-a971-f52a5dc57963_icon-product-tampons-Nude.png?auto=compress,format';
export const PRODUCT_CBD_BALM_IMG =
  'https://images.prismic.io/ecommerce-website/dd14e53e-8716-4575-ae8a-6851daf4815b_cbd_balm.png?auto=compress,format';
export const PRODUCT_ELVIE_IMG =
  'https://images.prismic.io/ecommerce-website/058db5a4-f214-4000-b013-7dc09294f609_pelvic_floor_training_60.png?auto=compress,format';
export const PRODUCT_OHNUT_IMG =
  'https://images.prismic.io/ecommerce-website/97515405-a6ad-4c2d-a569-5653a3a48bd5_penis_rings_60.png?auto=compress,format';

export const MEDICATION_CLOTRIMAZOLE_IMG =
  'https://images.prismic.io/ecommerce-website/c5212a29-d205-4f22-9bc2-021922f38ded_Clotrimazole.png?auto=compress,format';
export const MEDICATION_CLOTRIMAZOLE_SMALL_IMG =
  'https://images.prismic.io/ecommerce-website/a2da8164-6890-4d25-ae3b-d0ac9bc44987_ClotrimazoleSmall.png?auto=compress,format';
export const MEDICATION_CLOTRIMAZOLE_CART_IMG =
  'https://images.prismic.io/ecommerce-website/51c71b49-25d2-4b63-8296-60e208253c46_Clotrimazole%402x.png?auto=compress,format';
export const MEDICATION_FLUCONAZOLE_IMG =
  'https://images.prismic.io/ecommerce-website/678af57d-5996-4e9d-8f13-eb03d20da0be_Fluconazole.png?auto=compress,format';
export const MEDICATION_FLUCONAZOLE_SMALL_IMG =
  'https://images.prismic.io/ecommerce-website/5761a21f-d970-4cd3-a60e-0430c89ba513_FluconazoleSmall.png?auto=compress,format';
export const MEDICATION_BORICACID_IMG =
  'https://images.prismic.io/ecommerce-website/c46fbdbc-90c1-48e7-a9aa-33dddaaf2d71_BoricAcid.png?auto=compress,format';
export const MEDICATION_BORICACID_SMALL_IMG =
  'https://images.prismic.io/ecommerce-website/d17c5a0e-3ee3-4340-baad-190a1a99220b_BoricAcidSmall.png?auto=compress,format';
export const MEDICATION_METRONIDAZOLE_IMG =
  'https://images.prismic.io/ecommerce-website/a9c32cc4-9bea-4611-8534-7723b54b0bdf_Metronidazole.png?auto=compress,format';
export const MEDICATION_METRONIDAZOLE_SMALL_IMG =
  'https://images.prismic.io/ecommerce-website/d02ad0c6-e266-4389-9ac5-c82aa137a6fa_MetronidazoleSmall.png?auto=compress,format';
export const MEDICATION_CLINDAMYCIN_IMG =
  'https://images.prismic.io/ecommerce-website/47904550-afdb-4bd7-8689-103f60798928_Clindamycin.png?auto=compress,format';
export const MEDICATION_CLINDAMYCIN_SMALL_IMG =
  'https://images.prismic.io/ecommerce-website/71ba6271-2a97-4be3-a4b3-08157d06069a_ClindamycinSmall.png?auto=compress,format';
export const MEDICATION_CLINDAMYCIN_CART_IMG =
  'https://images.prismic.io/ecommerce-website/5b2ad0bd-3a1a-4969-8408-e9125a87976d_Clindamycin%402x.png?auto=compress,format';
export const MEDICATION_RELACTAGEL_IMG =
  'https://images.prismic.io/ecommerce-website/e753d94a-f58c-4252-908e-91621abade43_RelactagelLarge+%281%29.png?auto=compress,format';
export const MEDICATION_RELACTAGEL_SMALL_IMG =
  'https://images.prismic.io/ecommerce-website/51c5d898-d7d5-4f67-94ef-85a9f0511584_Relactagel+%281%29.png?auto=compress,format';
export const MEDICATION_CHLAMYDIA_IMG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZikLb_Pdc1huKvFv_ChlamydiaTreatment%2BMed.svg';
export const MEDICATION_TRICHOMONIASIS_IMG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZipEd_Pdc1huKytY_TrichoTreatment%2BMed.svg';
export const MEDICATION_MGENITALIUM_IMG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZipEdvPdc1huKytW_MycoplasmaTreatment%2BMed.svg';
export const MEDICATION_UREAPLASMA_IMG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZipEePPdc1huKyta_UreaplasmaTreatment%2BMed.svg';
export const MEDICATION_THRUSH_IMG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZipEevPdc1huKytc_CandidaTreatment%2BMed.svg';
export const MEDICATION_BV_IMG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZipEefPdc1huKytb_BVTreatment%2BMed.svg';

export const ENDO_HEADLINE_SVG = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/94d36348-ecff-4913-9e2f-07293df7b1a8_EndoHeadline.svg';
export const REF_CODE_BESTIES_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/1c0afc37-2621-4102-9cf0-927bbc209e6e_referral-code-besties.svg';

export const UTERUS_CONDITION_PID_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/c56ec644-c509-4fd6-a770-e3b3c2498d43_ConditionPID.svg';
export const UTERUS_CONDITION_PCOS_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/809aae01-072b-4e81-9f8a-0bed54dbea18_ConditionPCOS.svg';
export const UTERUS_CONDITION_NORMAL_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/efb243ab-41bd-4aa3-acb5-04f969dde9a7_ConditionNormal.svg';
export const UTERUS_CONDITION_FIBROIDS_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/281e7d16-6d90-434a-ba5c-c01fffa66f2f_ConditionFibroids.svg';
export const UTERUS_CONDITION_ENDO_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/b5e80476-069e-4648-8896-64fd98e5777b_ConditionEndometriosis.svg';
export const UTERUS_CONDITION_ADENO_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/0f3f0ae9-f943-4b92-938c-fd780c52993a_ConditionAdenomyosis.svg';

export const AEROBIC_DYSBIOSIS_CANDIDA_NO_MYCOPLASMA_NO_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/8857754c-aaf3-46da-9af2-dabedd9a848c_AerobicDysbiosisCandidaNoMycoplasmaNo.svg';
export const AEROBIC_DYSBIOSIS_CANDIDA_NO_MYCOPLASMA_YES_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/0dbf93ff-4158-4023-b284-7f689d853268_AerobicDysbiosisCandidaNoMycoplasmaYes.svg';
export const AEROBIC_DYSBIOSIS_CANDIDA_YES_MYCOPLASMA_NO_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/6a4bfc6e-140a-4966-9d17-3b030aae719f_AerobicDysbiosisCandidaYesMycoplasmaNo.svg';
export const AEROBIC_DYSBIOSIS_CANDIDA_YES_MYCOPLASMA_YES_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/73f26559-ee71-4890-aecf-202de3b7bde8_AerobicDysbiosisCandidaYesMycoplasmaYes.svg';
export const ANAEROBIC_DYSBIOSIS_CANDIDA_NO_MYCOPLASMA_NO_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/09e862d3-f2e0-4da4-a6fb-8510940932af_AnaerobicDysbiosisCandidaNoMycoplasmaNo.svg';
export const ANAEROBIC_DYSBIOSIS_CANDIDA_NO_MYCOPLASMA_YES_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/af435950-0810-4ec3-9f74-e3d3bd809112_AnaerobicDysbiosisCandidaNoMycoplasmaYes.svg';
export const ANAEROBIC_DYSBIOSIS_CANDIDA_YES_MYCOPLASMA_NO_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/9e1d6cb0-a45f-47d3-991c-fbe1107a6277_AnaerobicDysbiosisCandidaYesMycoplasmaNo.svg';
export const ANAEROBIC_DYSBIOSIS_CANDIDA_YES_MYCOPLASMA_YES_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/3ce1bb53-39a7-4a9a-ab90-30a0f1ae5f7d_AnaerobicDysbiosisCandidaYesMycoplasmaYes.svg';
export const MIXED_DYSBIOSIS_CANDIDA_NO_MYCOPLASMA_NO_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/b96be01f-4f06-4589-92a8-60314e27333c_MixedDysbiosisCandidaNoMycoplasmaNo.svg';
export const MIXED_DYSBIOSIS_CANDIDA_NO_MYCOPLASMA_YES_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/851f5900-4530-4742-bbb2-e957ccb66f53_MixedDysbiosisCandidaNoMycoplasmaYes.svg';
export const MIXED_DYSBIOSIS_CANDIDA_YES_MYCOPLASMA_NO_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/3f55adac-5b93-485c-aed1-293ac1bf7320_MixedDysbiosisCandidaYesMycoplasmaNo.svg';
export const MIXED_DYSBIOSIS_CANDIDA_YES_MYCOPLASMA_YES_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/40a9965c-f596-463c-8955-6c1bd52d851f_MixedDysbiosisCandidaYesMycoplasmaYes.svg';
export const MODERATE_DYSBIOSIS_CANDIDA_NO_MYCOPLASMA_NO_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/630ee308-ab09-48a7-a3e4-bf018c9bcf8e_ModerateDysbiosisCandidaNoMycoplasmaNo.svg';
export const MODERATE_DYSBIOSIS_CANDIDA_NO_MYCOPLASMA_YES_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/8cf2b50c-0a47-4442-ae41-15a6bcf69be6_ModerateDysbiosisCandidaNoMycoplasmaYes.svg';
export const MODERATE_DYSBIOSIS_CANDIDA_YES_MYCOPLASMA_NO_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/8e52d71d-b9bc-4d0a-a00a-17cd4bdf1969_ModerateDysbiosisCandidaYesMycoplasmaNo.svg';
export const MODERATE_DYSBIOSIS_CANDIDA_YES_MYCOPLASMA_YES_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/40a82438-f840-4383-92df-af6f9740901e_ModerateDysbiosisCandidaYesMycoplasmaYes.svg';
export const NORMOCENOSIS_CANDIDA_NO_MYCOPLASMA_NO_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/b7f9d1b1-8581-4e65-80e8-aaae144145d0_NormocenosisCandidaNoMycoplasmaNo.svg';
export const NORMOCENOSIS_CANDIDA_NO_MYCOPLASMA_YES_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/eed03335-44ee-45d6-b1e0-c1cca929e13e_NormocenosisCandidaNoMycoplasmaYes.svg';
export const NORMOCENOSIS_CANDIDA_YES_MYCOPLASMA_NO_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/78adad12-dd24-4650-b835-c0870f06f1ce_NormocenosisCandidaYesMycoplasmaNo.svg';
export const NORMOCENOSIS_CANDIDA_YES_MYCOPLASMA_YES_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/e1dbc4a8-7529-4237-924c-962f1cbc4c5e_NormocenosisCandidaYesMycoplasmaYes.svg';

export const CART_ITEM_VMS_IMG = 'https://images.prismic.io/ecommerce-website/eeb15780-8ed4-416e-a9cd-bf59fed3e68c_vms.png?auto=compress,format';
export const CART_ITEM_PADS_LINER =
  'https://images.prismic.io/ecommerce-website/Zo1hsR5LeNNTw9Ca_icon-product-bamboo-liners.png?auto=format,compress';
export const CART_ITEM_PADS_REGULAR =
  'https://images.prismic.io/ecommerce-website/ZowHTx5LeNNTw6LE_icon-product-pads-regular.png?auto=format,compress';
export const CART_ITEM_PADS_SUPER = 'https://images.prismic.io/ecommerce-website/ZowHUR5LeNNTw6LG_icon-product-pads-super.png?auto=format,compress';
export const CART_ITEM_PADS_SUPER_PLUS =
  'https://images.prismic.io/ecommerce-website/ZowHUB5LeNNTw6LF_icon-product-pads-super%2B.png?auto=format,compress';
export const CART_ITEM_VALETINES_IMG =
  'https://images.prismic.io/ecommerce-website/cc50d434-ffb4-44b6-a1a0-ede81ae86e78_valentines_sampler.png?auto=compress,format';
export const CART_ITEM_VALENTINESBOX_IMG =
  'https://images.prismic.io/ecommerce-website/8576b156-7b02-4251-9eda-53cc643dee26_valentines_box.png?auto=compress,format';
export const CART_ITEM_VAGINAMATTERS_IMG =
  'https://images.prismic.io/ecommerce-website/c70b1323-bee0-4966-b710-85413783086c_vagina_matters_book.png?auto=compress,format';
export const CART_ITEM_CANDLE_IMG =
  'https://images.prismic.io/ecommerce-website/4082aece-0768-4350-8cfe-796c25d026a5_vagina_candle.png?auto=compress,format';
export const CART_ITEM_TAMPONPADS_IMG =
  'https://images.prismic.io/ecommerce-website/c660f75b-c5e5-4aa1-8993-ebd5e1644af8_tampons_and_pads.png?auto=compress,format';
export const CART_ITEM_TAMPONTIN_IMG =
  'https://images.prismic.io/ecommerce-website/6bbb9a7a-d3ef-4e9e-833f-34c3e70ac244_tampon_tin.png?auto=compress,format';
export const CART_ITEM_TAMPONPOUCH_IMG =
  'https://images.prismic.io/ecommerce-website/866ef781-2fc2-44e8-af05-5590ea7bd3de_tampon_pouch.png?auto=compress,format';
export const CART_ITEM_TAMPONBOX_IMG =
  'https://images.prismic.io/ecommerce-website/6ddb2593-9e0f-49dc-997d-75e237b38e3c_tampon_box.png?auto=compress,format';
export const CART_ITEM_SAMPLER_IMG =
  'https://images.prismic.io/ecommerce-website/5f89704f-b89d-4156-ade7-9a0d03f0ba27_sampler.png?auto=compress,format';
export const CART_ITEM_PROVIOTICS_IMG =
  'https://images.prismic.io/ecommerce-website/f07886fe-cff0-434c-9633-78141f4d3adf_icon-product-proviotics.png?auto=compress,format';
export const CART_ITEM_PADS_IMG = 'https://images.prismic.io/ecommerce-website/575645f9-7d06-4b9c-a028-286d86f9278c_pads.png?auto=compress,format';
export const CART_ITEM_PADS_STACK_IMG =
  'https://images.prismic.io/ecommerce-website/Zo0bgx5LeNNTw7y__icon-product-pads-all%402x.png?auto=format,compress';
export const CART_ITEM_MASKPINK_IMG =
  'https://images.prismic.io/ecommerce-website/fc12905d-8bb1-46fb-9efc-436857cd92fe_mask_pink.png?auto=compress,format';
export const CART_ITEM_MASKLIGHT_IMG =
  'https://images.prismic.io/ecommerce-website/f4ab1d4e-d2c7-4ece-a217-15bc7d273ff2_mask_light.png?auto=compress,format';
export const CART_ITEM_MASKDARK_IMG =
  'https://images.prismic.io/ecommerce-website/b50d91c7-e922-4ee7-bf14-9c57bf1288bf_mask_dark.png?auto=compress,format';
export const CART_ITEM_MASKBLUE_IMG =
  'https://images.prismic.io/ecommerce-website/47bfa9eb-839c-4d56-b25a-ec4795c935d8_mask_blue.png?auto=compress,format';
export const CART_ITEM_TAMPONS_IMG =
  'https://images.prismic.io/ecommerce-website/0064f1b3-beef-4ed8-8c7c-8e2e1e2752f8_icon-product-tampons.png?auto=compress,format';
export const CART_ITEM_TAMPONSNUDE_IMG =
  'https://images.prismic.io/ecommerce-website/870f5e9f-89b6-45af-b002-83a79a50db2c_icon-product-tampons-nude.png?auto=compress,format';
export const CART_ITEM_PPC_IMG =
  'https://images.prismic.io/ecommerce-website/569ef7bb-34eb-4a0b-a4ed-cae12e88e89b_icon-product-ppc.png?auto=compress,format';
export const CART_ITEM_PADSSUPER_IMG =
  'https://images.prismic.io/ecommerce-website/83b00482-e359-46f0-9acc-ac0686e6793e_icon-product-pads-super%2Bsuper.png?auto=compress,format';
export const CART_ITEM_BALM_IMG =
  'https://images.prismic.io/ecommerce-website/8530e366-7883-4e8f-afd9-8de960a1cb88_cbd_balm.png?auto=compress,format';
export const CART_ITEM_BLMPIN_IMG =
  'https://images.prismic.io/ecommerce-website/4c89e91f-e68f-4c62-a8ee-f9e4589639d1_blm_pin.png?auto=compress,format';
export const CART_ITEM_GIFTCARD_IMG =
  'https://images.prismic.io/ecommerce-website/285a7f69-509e-4a92-811c-1c71613ecacd_gift_card.png?auto=compress,format';
export const CART_ITEM_TAMPONBUNDLECBD_IMG =
  'https://images.prismic.io/ecommerce-website/11088b57-22b8-4760-a2bb-30c95c96a3aa_icon-bundle-cbd-bundle.png?auto=compress,format';
export const CART_ITEM_TAMPONBUNDLE_IMG =
  'https://images.prismic.io/ecommerce-website/d6c7a199-07c0-4cc6-ba9a-247f13bfe017_icon-bundle-period-care.png?auto=compress,format';
export const CART_ITEM_TAMPONBUNDLEHEAVY_IMG =
  'https://images.prismic.io/ecommerce-website/4ba50986-671d-42b5-b97e-cb785d4a47b7_icon-bundle-heavy-flow.png?auto=compress,format';
export const CART_ITEM_CONSULTATION_IMG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/7d577ce4-8e35-42b3-a3b0-62a8b44d5caa_icon-product-consultation.svg';
export const CART_ITEM_FESTIVE_BUNDLE_IMG =
  'https://images.prismic.io/ecommerce-website/7785a557-0469-4b41-b7fb-18fccab2301b_icon-bundle-festive-bundle%402x.png?auto=compress,format';

export const DID_YOU_KNOW_BULB_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/168cb823-fe0b-4a25-b94e-cf2ca717e54f_DidYouKnowBulb.svg';

export const FISTS_BUMP_REFERRAL_TEAM_SVG =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZjvK6kMTzAJOCpGX_fists-bump-referral-team.svg';

export const HAMBURGER_BREAKPOINT = 1050;
export const NAV_MENU_BREAKPOINT = 680;
export const NAVBAR_HEIGHT = 70;
export const NAV_SEARCH_VITALS_INITIAL_COUNT = 4;
export const NAV_SEARCH_VITALS_SHOW_MORE_COUNT = 8;
export const NAV_SEARCH_PRODUCTS_INITIAL_COUNT = 4;
export const NAV_SEARCH_PRODUCTS_SHOW_MORE_COUNT = 12;
export const NAV_SEARCH_LANDINGS_INITIAL_COUNT = 4;
export const NAV_SEARCH_LANDINGS_SHOW_MORE_COUNT = 8;
export const SNOW_HERO_OVERLAY =
  'https://images.prismic.io/ecommerce-website/eb86b1be-add3-4dc6-895e-15ee29909fe0_d_snowflakes_tampons%402x.png?auto=compress,format';
/*
 * Algolia search works by crawling our website and creating a result for each url.
 * Some of the ulrs do not match our search categories (products, vitals, landing) so we ignore them here.
 */
export const IGNORED_ALGOLIA_SEARCH_URLS = [
  '/',
  '/reset-password/',
  '/period-pain-clinic-waitlist/',
  '/forgot-password/',
  '/404.html',
  '/index/',
  '/terms/',
  '/privacy/'
];

export const DT_INSTRUCTIONS_VIDEO_RETAIL = 'https://www.youtube.com/embed/4kg8DVEqXm8';
export const DT_INSTRUCTIONS_VIDEO_GEL_PACK = 'https://www.youtube.com/embed/-SmyXUPyBxM';
export const DT_INSTRUCTIONS_VIDEO_BLUEBOX = 'https://www.youtube.com/embed/7nm5MH5Lw2Y';
export const VALENTINES_DAY_BOX_IMAGE =
  'https://images.prismic.io/ecommerce-website/12f439bf-532c-4195-a6b7-3226f6875091_box-tin-heart-desktop.png?auto=compress,format';

export const DIAGNOSTIC_TAMPON_PRODUCT_CODES = [
  ProductCode.HPVScreening,
  ProductCode.STIScreening,
  ProductCode.VMSEssential,
  ProductCode.VMSAdvanced,
  ProductCode.ScreeningKit,
  ProductCode.VaginalHealthBundle
];

/**
 * Defines standard screen widths for responsive design, facilitating targeting of
 * different device sizes from small mobile devices to large laptops.
 *
 * Example:
 * ```typescript
 * const isMobile = !useDesktop(BREAKPOINT.tablet);
 * `;
 * ```
 *
 */
export const BREAKPOINT = {
  mobileS: 375, // Smaller smartphones.
  mobileM: 425, // Average smartphones.
  mobileL: 600, // Large smartphones or small tablets.
  tablet: 768, // Standard tablets.
  laptop: 1024, // Small laptops.
  laptopM: 1280, // Medium laptops.
  laptopL: 1440, // Larger laptops.
  dashboardPage: 1300 // Some dashboard pages break at 1300. Dashboard redesign is next on the list and will unify breakpoints.
};

/**
 * Provides media query strings keyed by device type for easy inclusion in CSS-in-JS.
 * Utilize these for responsive designs within styled-components or similar libraries.
 *
 * Example:
 * ```typescript
 * import { MEDIA } from '@constants';
 * const StyledComponent = styled.div`
 *   color: black;
 *   ${MEDIA.tablet} {
 *     color: red; // Applies on tablet and above.
 *   }
 * `;
 * ```
 */
export const MEDIA = {
  mobileS: `@media (max-width: ${BREAKPOINT.mobileS}px)`,
  mobileM: `@media (max-width: ${BREAKPOINT.mobileM}px)`,
  mobileL: `@media (max-width: ${BREAKPOINT.mobileL}px)`,
  tablet: `@media (max-width: ${BREAKPOINT.tablet}px)`,
  laptop: `@media (max-width: ${BREAKPOINT.laptop}px)`,
  laptopM: `@media (max-width: ${BREAKPOINT.laptopM}px)`,
  laptopL: `@media (max-width: ${BREAKPOINT.laptopL}px)`
};

export const SCREENING_ICONS = {
  all: 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZgRlNMt2UUcvBPJO_MicrobiomeScreening.svg',
  allWithCircle: 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZgRjP8t2UUcvBPIm_ScreeningsCircleIcon.svg',
  sti: 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZgRlNct2UUcvBPJP_STIs.svg',
  stiMono: 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZgRntMt2UUcvBPKV_StiMono.svg',
  stiWithCircle: 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZgRjPct2UUcvBPIk_STICircleIcon.svg',
  vms: 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZgRlNst2UUcvBPJQ_VMS.svg',
  vmsMono: 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZgRns8t2UUcvBPKU_MicrobiomeMono.svg',
  vmsWithCircle: 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZgRjPst2UUcvBPIl_VMSCircleIcon.svg',
  hpv: 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZgRlN8t2UUcvBPJR_HPV.svg',
  hpvMono: 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZgRns8t2UUcvBPKU_MicrobiomeMono.svg',
  hpvWithCircle: 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZgRjPMt2UUcvBPIj_HPVCircleIcon.svg'
};

export const ALL_SCREENING_TYPES = [ScreeningType.Vms, ScreeningType.Sti, ScreeningType.Hpv];

export const NURSE_ICON = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZhcPyDjCgu4jzvMg_NurseIcon.svg';
export const PURPLE_ALERT_ICON = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZgzvT8t2UUcvBWPV_PurpleAlert.svg';
export const INFO_ICON = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZgzvUMt2UUcvBWPW_Info.svg';
export const GOLD_ALERT_ICON = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZgzvTst2UUcvBWPU_GoldAlert.svg';
export const ORDER_ICON = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/Zjxs9UMTzAJOCpd5_Order.svg';
export const RED_ERROR_ICON = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/Zh_pdPPdc1huKlDX_Icon-Red-Error.svg';
export const PROCESSING_ICON = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZjM6x0MTzAJOCfAD_ReviewAssessMagnifyingGlassTampon.svg';
export const HEART_ICON = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZgzvTct2UUcvBWPT_Heart.svg';
export const REFRESH_ICON = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZgzzY8t2UUcvBWPu_Refresh.svg';
export const CHEVRON_DOWN_ICON = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZgzzZMt2UUcvBWPv_ChevronDown.svg';
export const CHEVRON_SMALL_ICON = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/Zg0UHct2UUcvBWbc_Chevron-small.svg';
export const SPECIALISTS_WITH_CIRCLE_ICON = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/Zg6urTskWekewDng_SpecialistsWithCircle.svg';
export const TAKE_YOUR_SAMPLE_WITH_CIRCLE_ICON =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/Zjpq-0MTzAJOCnlR_TakeYourSampleWithCircle.svg';
export const SEND_YOUR_SAMPLE_BACK_WITH_CIRCLE_ICON =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/Zjpq-kMTzAJOCnlQ_SendYourSampleBackWithCircle.svg';
export const PAP_SMEAR_WITH_CIRCLE_ICON = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZiEw1_Pdc1huKnH4_PapSmearWithCircle.svg';
export const PAIN_RELIEF_MEDS_WITH_CIRCLE_ICON =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/Zg6urDskWekewDnd_PainReliefMedsWithCircle.svg';
export const PPC_SUMMARY_WITH_CIRCLE_ICON = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/Zg6uqzskWekewDnc_PPCSummaryWithCircle.svg';
export const NUTRITIONISTS_WITH_CIRCLE_ICON =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/Zg6uqjskWekewDnb_NutritionistsWithCircle.svg';
export const PROVIOTICS_WITH_CIRCLE_ICON = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZhmZHzjCgu4jzy3M_ProVioticsWithCircle.svg';
export const TAMPONS_WITH_CIRCLE_ICON = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZhmZHjjCgu4jzy3L_TamponsWithCircle.svg';
export const REACH_OUT_EMAIL = 'hello@yourdaye.com';
export const SCREENING_FREE_KIT_MAIL_TO_PREFIX = 'mailto:hello@yourdaye.com?subject';
export const SCREENING_FREE_KIT_MAIL_TO_SUFFIX = ': Inconclusive result - replacement kit request';

export const LAB_RESULTS_EXPERTS_IMAGE =
  'https://images.prismic.io/ecommerce-website/ZrDmd0aF0TcGIsG1_lab-accredidations-us-uk.png?auto=format,compress';

export const STI_HIV_SYPHILIS_LINK = 'https://test.me/home-sti-test-kits/hiv-syphilis-screen/';
export const HPV_FREE_CLINIC_UK_LINK = 'https://www.nhs.uk/service-search/sexual-health/find-a-sexual-health-clinic/';
export const HPV_BOOTS_VACCINATION_LINK = 'https://www.boots.com/online/pharmacy-services/hpv-vaccination-service';

export const MICROBIOME_RESEARCH_LAB_ICON = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/Zh5NrTjCgu4jz2JZ_MicroscopeResearchLab.svg';
export const PAP_SMEAR_ICON = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZiE2UfPdc1huKnNP_PapSmear.svg';
export const PROVIOTICS_SMALL_ICON =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/6bded76f-a965-4f4a-a630-02076c561b70_proviotics-60x60px.svg';
export const VMS_SMALL_CIRCLE_ICON = 'https://images.prismic.io/ecommerce-website/Zh62RkaI3ufuUO_J_ProvioticsWithCircle.png?auto=format,compress';
export const HPV_VACCINATION_AND_PREVENTION_ICON =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/Zh5un0aI3ufuUOKS_HpvVaccinationAndPrevention.svg';

export const SCREENING_RESULTS_RETURNED_STATUS = 'results_returned';

export const VITALS_BLOG_ICON = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZijScfPdc1huKuX__VitalsBlog.svg';

export const CERTIFICATION_ISO_13485 = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZkX8ryol0Zci9NAd_certification-iso-13485.svg';
export const CERTIFICATION_B_CORP = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZkX8qyol0Zci9NAX_certification-b-corp.svg';
export const CERTIFICATION_GOTS = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZkX8riol0Zci9NAc_certification-gots.svg';
export const CERTIFICATION_GMP = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZkX8rSol0Zci9NAb_certification-gmp.svg';
export const CERTIFICATION_CLIMATE_PARTNER =
  'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZkX8rCol0Zci9NAY_certification-climate-partner.svg';
export const CERTIFICATION_A21 = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZkX9Wyol0Zci9NBL_certification-A21.svg';

export const WAVYLINE = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZkX_Iyol0Zci9NEK_WavyLine.svg';

export const DASHBOARD_RESULT_PATHS = ['/dashboard/microbiome-screening/'];

export const PRICES_WITHOUT_DISCOUNT = {
  [ProductCode.HPVAdvanced]: 168,
  [ProductCode.VMSPremium]: 257,
  [ProductCode.VMSAdvanced]: 188
} as Record<ProductCode, number>;

export const PAD_SMALL_ICONS = {
  [PadSizeType.LINER]: 'https://images.prismic.io/ecommerce-website/ZnHpoZm069VX12_U_PadLinerIcon.png?auto=format,compress',
  [PadSizeType.REGULAR]: 'https://images.prismic.io/ecommerce-website/ZnHpopm069VX12_V_PadRegularIcon.png?auto=format,compress',
  [PadSizeType.SUPER]: 'https://images.prismic.io/ecommerce-website/ZnHppJm069VX12_X_PadSuperIcon.png?auto=format,compress',
  [PadSizeType.SUPER_PLUS]: 'https://images.prismic.io/ecommerce-website/ZnHpo5m069VX12_W_PadSuperPlusIcon.png?auto=format,compress'
};

export const PAD_BIG_ICONS = {
  [PadSizeType.LINER]: 'https://images.prismic.io/ecommerce-website/ZnHppZm069VX12_Y_PadLinerBigIcon.png?auto=format,compress',
  [PadSizeType.REGULAR]: 'https://images.prismic.io/ecommerce-website/ZnHpppm069VX12_Z_PadRegularBigIcon.png?auto=format,compress',
  [PadSizeType.SUPER]: 'https://images.prismic.io/ecommerce-website/ZnHpoJm069VX12_T_PadSuperBigIcon.png?auto=format,compress',
  [PadSizeType.SUPER_PLUS]: 'https://images.prismic.io/ecommerce-website/ZnHpp5m069VX12_a_PadSuperPlusBigIcon.png?auto=format,compress'
};

export const PAD_BLOOD_DROPLET_COUNTS = {
  [PadSizeType.LINER]: 1,
  [PadSizeType.REGULAR]: 2,
  [PadSizeType.SUPER]: 3,
  [PadSizeType.SUPER_PLUS]: 4
};

export const PAD_STACK_COUNTS = {
  [PadSizeType.LINER]: 24,
  [PadSizeType.REGULAR]: 12,
  [PadSizeType.SUPER]: 12,
  [PadSizeType.SUPER_PLUS]: 10
};

export const PAD_STACK_PRODUCT_IDS = {
  [PadSizeType.LINER]: ProductCode.PadsBox24L,
  [PadSizeType.REGULAR]: ProductCode.PadsBox12R,
  [PadSizeType.SUPER]: ProductCode.PadsBox12S,
  [PadSizeType.SUPER_PLUS]: ProductCode.PadsBox10SP
};

export const BLOOD_DROPLET_ICON = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZnHqJJm069VX12_i_BloodDroplet.svg';

export const MAX_PAD_STACK_COUNT = 9;

export const INSTAGRAM_LINK = 'https://www.instagram.com/yourdaye/?hl=en';
export enum FeedbackStep {
  Emojis = 1,
  Referral,
  Improvement,
  Gratitude
}

export enum HearAboutUsOption {
  Friend = 'friend',
  SocialMedia = 'social media',
  Search = 'search',
  Article = 'article',
  Event = 'event',
  Advert = 'advert'
}

export const PADS_TECH_SPECIFICS_IMAGE_2882 =
  'https://images.prismic.io/ecommerce-website/ZnvknZbWFbowe3f3_PadsTechSpecifics-1.png?auto=format,compress';
export const PADS_TECH_SPECIFICS_IMAGE_2160 =
  'https://images.prismic.io/ecommerce-website/ZnvknpbWFbowe3f4_PadsTechSpecifics-2.png?auto=format,compress';
export const PADS_TECH_SPECIFICS_IMAGE_1602 =
  'https://images.prismic.io/ecommerce-website/Znvkm5bWFbowe3f2_PadsTechSpecifics.png?auto=format,compress';

export const CHEAPEST_TAMPON_VARIANT = 'nakedregular';

export const SUBSCRIPTION_PRODUCT_CODES = [ProductCode.TamponBox, ProductCode.Proviotics, ProductCode.ScreeningKit, ProductCode.Pads];
export const RECAPTCHA_ID = 'recaptcha-container';

export const FLEX_ICON = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZrNpskaF0TcGIv1n_FlexIcon.svg';
export const FLEX_ICON_WHITE = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZrPP_0aF0TcGIwwy_FlexIconWhite.svg';
export const EXCLAMATION_WITH_CIRCLE_ICON = 'https://ecommerce-website.cdn.prismic.io/ecommerce-website/ZrNps0aF0TcGIv1p_ExclamationWithCircle.svg';
export const FLEX_SAVE_UP_TO_PERCENT = 30;
export const HSA_FSA_COUNTRIES = [CountryCode.US];

export const ARTICLE_PROGRESS_BAR_HEIGHT = 61;
export const ARTICLE_BODY_WIDTH_DESKTOP = 800;
export const ARTICLE_BODY_WIDTH_LAPTOP = 600;
export const ARTICLE_AUTHOR_WIDTH_DESKTOP = 200;
export const ARTICLE_AUTHOR_WIDTH_TABLET = 600;

export const DEFAULT_KLAVIYO_LIST_ID = 'LDYzNZ';
export const DEFAULT_KLAVIYO_LIST_ID_US = 'QVAGwg';
