import React from 'react';
import { LocationProvider } from '@reach/router';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Provider } from '@components/Context';
import { NavProvider } from '@components/navigation/Context';
import { RECAPTCHA_ID } from './src/constants';

export default function Wrapper({ element }) {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return (
    <LocationProvider>
      <Provider>
        <NavProvider>{element}</NavProvider>
        <RecaptchaContainer id={RECAPTCHA_ID} />
      </Provider>
    </LocationProvider>
  );
}

Wrapper.propTypes = {
  element: PropTypes.node,
};

const RecaptchaContainer = styled.div`
  & > .grecaptcha-badge {
    visibility: hidden;
    margin-bottom: 75px;
  }
`;