export default async function checkResStatus(res: Response) {
  if (res.status === 204) {
    return undefined;
  }
  if (res.status === 200) {
    return res.json();
  }
  const body = await res.text();
  throw { status: res.status, body };
}
